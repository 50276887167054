import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Paper, Switch, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, withStyles,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import styles from '../../style';
import { emptyArray } from '../../util/util';

const ActivityTable = (props) => {
  const {
    classes,
    activity,
    info,
    rowsPerPage,
    rowsPerPageChanged,
    currentPage,
    currentPageChanged,
    refreshPage,
    showExternalOnly,
    toggleExternalOnly,
  } = props;
  const { limit, total } = info;

  const loading = ((activity === emptyArray) || (limit === undefined) || (total === undefined));

  const tablePagination = (
    <TablePagination
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[100, 500, 1000]}
      component="div"
      count={total}
      page={currentPage}
      onChangePage={(event, newPage) => currentPageChanged(newPage)}
      onChangeRowsPerPage={(event) => rowsPerPageChanged(parseInt(event.target.value, 10))}
    />
  );

  return (
    <div className={classes.activityTableDiv}>
      {loading && <LinearProgress />}
      {loading || (
        <Paper>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={1}>
              <FormControlLabel
                control={(
                  <IconButton onClick={() => refreshPage()}>
                    <RefreshIcon color="primary" />
                  </IconButton>
                )}
                label="Refresh"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={(
                  <Switch
                    color="primary"
                    checked={showExternalOnly}
                    onChange={() => toggleExternalOnly(!showExternalOnly)}
                  />
                )}
                label="Show only external users"
              />
            </Grid>
          </Grid>
          {tablePagination}
          <Table size="small">
            <TableHead>
              <TableRow hover>
                <TableCell style={{ width: 300 }}>email</TableCell>
                <TableCell style={{ width: 150 }}>Company</TableCell>
                <TableCell style={{ width: 300 }}>Created at</TableCell>
                <TableCell style={{ width: 100 }}>Status</TableCell>
                <TableCell style={{ width: 300 }}>Endpoint</TableCell>
                <TableCell>Full url</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {activity.map((a) => (
                <TableRow key={a.id} hover>
                  <TableCell>{a.email}</TableCell>
                  <TableCell>{a.company}</TableCell>
                  <TableCell>{a.created_at}</TableCell>
                  <TableCell>{a.status}</TableCell>
                  <TableCell>{a.endpoint}</TableCell>
                  <TableCell>{a.url}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {tablePagination}
        </Paper>
      )}
    </div>
  );
};

ActivityTable.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  activity: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  info: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageChanged: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  currentPageChanged: PropTypes.func.isRequired,
  refreshPage: PropTypes.func.isRequired,
  showExternalOnly: PropTypes.bool.isRequired,
  toggleExternalOnly: PropTypes.func.isRequired,
};

export default withStyles(styles)(ActivityTable);
