import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, Tooltip, withStyles,
} from '@material-ui/core';
import styles from '../../style';

const KeyQuantitiesSelector = (props) => {
  const {
    metrics,
    selectedMetric,
    selectedHistogramType,
    selectedInterval,
    onMetricChange,
    selectedFaceted,
    setSelectedFaceted,
  } = props;

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={3}>
          <Tooltip
            title="Choose the metric to be displayed"
            placement="right"
          >
            <FormControl>
              <InputLabel>Metric</InputLabel>
              <Select
                defaultValue=""
                onChange={onMetricChange}
                value={selectedMetric}
                inputProps={{ name: 'metric' }}
              >
                {metrics.map((metric) => {
                  const formattedMetric = metric.replace(/_/g, ' ');
                  const upperMetric = formattedMetric.charAt(0).toUpperCase()
                    + formattedMetric.slice(1);

                  return (
                    <MenuItem value={metric} key={metric}>
                      {upperMetric}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>

        <Grid item xs={3}>
          <Tooltip
            title="Choose the scale for y axis"
            placement="right"
          >
            <FormControl>
              <InputLabel>Y Axis</InputLabel>
              <Select
                defaultValue=""
                onChange={onMetricChange}
                value={selectedHistogramType}
                inputProps={{ name: 'histogram' }}
              >
                <MenuItem value="count">
                  Draws count
                </MenuItem>
                <MenuItem value="percent">
                  Percent
                </MenuItem>
                <MenuItem value="probability">
                  Probability
                </MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>

        <Grid item xs={3}>
          <Tooltip
            title="Choose the interval that will be shown on the chart"
            placement="left"
          >
            <FormControl>
              <InputLabel>Interval</InputLabel>
              <Select
                defaultValue=""
                onChange={onMetricChange}
                value={selectedInterval}
                inputProps={{ name: 'interval' }}
                disabled={!selectedFaceted}
              >
                <MenuItem value="none">
                  None
                </MenuItem>
                <MenuItem value="50">
                  50%
                </MenuItem>
                <MenuItem value="90">
                  90%
                </MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>

        <Grid item xs={3}>
          <Tooltip
            title="Choose if histogram should be displayed on one or faceted charts"
            placement="left"
          >
            <FormControlLabel
              control={(
                <Switch
                  color="primary"
                  checked={selectedFaceted}
                  onChange={() => setSelectedFaceted(!selectedFaceted)}
                />
              )}
              label="Faceted"
            />
          </Tooltip>
        </Grid>

      </Grid>
    </div>
  );
};

KeyQuantitiesSelector.propTypes = {
  metrics: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedMetric: PropTypes.string.isRequired,
  selectedHistogramType: PropTypes.string.isRequired,
  selectedInterval: PropTypes.string.isRequired,
  onMetricChange: PropTypes.func.isRequired,
  selectedFaceted: PropTypes.bool.isRequired,
  setSelectedFaceted: PropTypes.func.isRequired,
};

export default withStyles(styles)(KeyQuantitiesSelector);
