import TabActivityStatus from './TabActivityStatus';

class DocsActivityStatus extends TabActivityStatus {
  static DOCS_LIST = 'docs_list';

  get all() {
    return TabActivityStatus.getTopLevelStatus(this.list);
  }

  get list() {
    return this.getActivityStatus(DocsActivityStatus.DOCS_LIST);
  }
}

export default DocsActivityStatus;
