import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Plot from 'react-plotly.js';
import styles from '../../style';
import { chartStyle, chartColorsEnvelope, basicLayout } from './charts';
import RandomIdCollection from '../../util/RandomIdCollection';

const KeyQuantitiesOTChart = (props) => {
  const {
    keyQuantitiesOverTime,
    selectedMetric,
    selectedInterval,
    selectedFaceted,
    runId,
  } = props;

  const keyQuantitiesOTPerMetric = keyQuantitiesOverTime.filter(
    (k) => k.metric === selectedMetric,
  )[0];
  const runIdShort = runId.slice(0, 4);
  const dataReady = (keyQuantitiesOTPerMetric !== undefined);
  const envelopeColors = chartColorsEnvelope(0.4);
  const lineColors = chartColorsEnvelope(0.6);

  const intervalTable = {
    50: '50% CrI',
    90: '90% CrI',
  };

  if (!dataReady) return <div />;

  const getTraces = () => {
    const intervalKey = intervalTable[selectedInterval];
    const traces = [];
    keyQuantitiesOTPerMetric.data.forEach((d, index) => {
      const { label } = d;

      const trace = {
        x: d.times,
        y: d.values.median,
        type: 'scatter',
        showlegend: true,
        name: label,
        line: {
          color: lineColors[index],
        },
      };
      const traceLow = (intervalKey !== undefined) ? {
        x: d.times,
        y: d.values[intervalKey].lower,
        type: 'scatter',
        name: label,
        line: {
          color: '#00000000',
        },
        showlegend: false,
      } : null;
      const traceHigh = (intervalKey !== undefined) ? {
        x: d.times,
        y: d.values[intervalKey].upper,
        type: 'scatter',
        fill: 'tonexty',
        fillcolor: envelopeColors[index],
        name: label,
        mode: 'none',
        line: {
          color: lineColors[index],
        },
        // showlegend: false,
      } : null;

      if ((traceLow !== null) && (traceHigh !== null)) {
        traces.push([traceLow, traceHigh]);
      } else {
        traces.push([trace]);
      }
    });
    return (selectedFaceted) ? traces : traces.flat();
  };

  const data = getTraces();
  const idGenerator = new RandomIdCollection();
  const layout = { ...basicLayout };
  layout.xaxis.title = {
    text: keyQuantitiesOTPerMetric.x_label,
  };
  layout.yaxis.title = {
    text: keyQuantitiesOTPerMetric.y_label,
  };
  layout.annotations = [
    {
      xref: 'paper',
      yref: 'paper',
      text: `run id: ${runIdShort}`,
      x: 1.0,
      y: -0.1,
      showarrow: false,
      font: {
        size: 12,
        color: 'rgba(0, 0, 0, 0.3)',
      },
    },
  ];

  if (selectedFaceted) {
    return (
      <div>
        {data.map((el) => (
          <Plot
            key={idGenerator.generateNewId()}
            useResizeHandler
            data={el}
            style={chartStyle}
            layout={layout}
          />
        ))}
      </div>
    );
  }
  return (
    <Plot
      key={idGenerator.generateNewId()}
      useResizeHandler
      data={data}
      style={chartStyle}
      layout={layout}
    />
  );
};

KeyQuantitiesOTChart.propTypes = {
  keyQuantitiesOverTime: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedMetric: PropTypes.string.isRequired,
  selectedInterval: PropTypes.string.isRequired,
  selectedFaceted: PropTypes.bool.isRequired,
  runId: PropTypes.string.isRequired,
};

export default withStyles(styles)(KeyQuantitiesOTChart);
