import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FormControl, Grid, InputLabel, MenuItem, Select, Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import CrmActions from './CrmActions';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import styles from '../../style';
import ApiRouter from '../../util/ApiRouter';
import ActivityChart from '../chart/ActivityChart';
import { emptyArray } from '../../util/util';
import CrmAction from '../../util/crmConstants';

const ActivityByDays = (props) => {
  const DEFAULT_DAYS = 100;
  const { classes, handleBreadcrumbs, isAdmin } = props;
  const [activity, setActivity] = useState(emptyArray);
  const [days, setDays] = useState(DEFAULT_DAYS);

  const fetchActivityData = useCallback(() => {
    axios
      .get(ApiRouter.getActivityByDays(days))
      .then((response) => setActivity(response.data));
  }, [days]);

  useEffect(() => {
    if (!isAdmin) return;
    handleBreadcrumbs(GecoBreadcrumbs.crmActivityByDays(days));
    fetchActivityData();
  }, [handleBreadcrumbs, fetchActivityData, isAdmin, days]);

  const webappActivity = activity.webapp !== undefined ? activity.webapp : [];
  const apiActivity = activity.api !== undefined ? activity.api : [];

  if (!isAdmin) return <div />;

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <CrmActions action={CrmAction.ACTIVITY_BY_DAYS} />
      <Typography variant="h4">
        Activity by days
      </Typography>
      <div className={classes.appBarSpacer} />
      <Grid container alignItems="flex-end" direction="row">
        <Grid item xs={12}>
          <FormControl>
            <InputLabel>Days</InputLabel>
            <Select
              onChange={(event) => setDays(event.target.value)}
              value={days}
            >
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <ActivityChart activity={webappActivity} title="Webapp usage" />
        </Grid>
        <Grid item xs={6}>
          <ActivityChart activity={apiActivity} title="Api usage" />
        </Grid>
      </Grid>
    </div>
  );
};

ActivityByDays.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ActivityByDays);
