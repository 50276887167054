/* eslint no-undef: 0 */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableHead, TableRow, TableCell, withStyles, TableBody, Box, Grid, FormControlLabel, Switch,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import styles from '../../style';
import RandomIdCollection from '../../util/RandomIdCollection';
import { generateColors } from '../chart/charts';

const PopulationSummaryTable = (props) => {
  const {
    classes, populationSummary, topRef,
  } = props;
  const [visualizeCategorical, setVisualizeCategorical] = useState(true);

  const populationFetched = Object.keys(populationSummary).length !== 0;

  const filteredPopulationSummary = populationFetched
    ? { ...populationSummary } : populationSummary;

  const subjectsCount = filteredPopulationSummary.subjects_count;

  const getTextAlign = (index) => ((index === 0) ? 'right' : 'center');

  const idGenerator = new RandomIdCollection();
  const canvasParams = [];
  const canvasWeight = 100;
  const canvasHeight = 6;
  const fillColors = generateColors(0.7);
  const strokeColors = generateColors(0.9);

  const getPct = (str) => {
    const re = /^\d+ \((\d{1,3}\.\d{1,2})%\)/;
    const pct = str.match(re);
    return (pct) ? parseFloat(pct[1]) : 0;
  };

  const getTrialArms = useCallback(() => (
    <TableHead>
      <TableRow>
        <TableCell>
          {' '}
        </TableCell>
        {filteredPopulationSummary.trial_arms.map((trialArmName) => (
          <TableCell key={trialArmName}>
            <Box textAlign="center">
              {trialArmName}
              <br />
              (N=
              {subjectsCount[trialArmName]}
              )
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  ), [filteredPopulationSummary]);

  const getCategoryHeader = useCallback((category) => (
    <TableRow>
      <TableCell>
        <Box fontWeight="fontWeightBold">
          {category}
        </Box>
      </TableCell>
      {filteredPopulationSummary.trial_arms.map(() => (
        <TableCell key={idGenerator.generateNewId()}>
          {' '}
        </TableCell>
      ))}
    </TableRow>
  ), [filteredPopulationSummary]);

  const getRowsForCategory = useCallback((category, useCanvas, categoryIndex) => {
    if (useCanvas) {
      // color bars should be added to the table
      return category.map((row) => (
        <TableRow hover key={idGenerator.generateNewId()}>
          {row.map((column, index) => {
            // for all columns other then first (that shows only category) add color bars
            if (index !== 0) {
              const pct = getPct(column);

              const canvasId = idGenerator.generateNewId();
              canvasParams.push({ canvasId, pct, categoryIndex });
              return (
                <TableCell key={idGenerator.generateNewId()}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                  >
                    <Grid item>
                      <Box textAlign={getTextAlign(index)}>
                        {column}
                      </Box>
                    </Grid>
                    <Grid item>
                      <canvas id={canvasId} width={canvasWeight} height={canvasHeight} />
                    </Grid>
                  </Grid>
                </TableCell>
              );
            }

            // color bars shouldn't be added to the table
            return (
              <TableCell key={idGenerator.generateNewId()}>
                <Box textAlign={getTextAlign(index)}>
                  {column}
                </Box>
              </TableCell>
            );
          })}
        </TableRow>
      ));
    }

    return category.map((row) => (
      <TableRow hover key={idGenerator.generateNewId()}>
        {row.map((column, index) => (
          <TableCell key={idGenerator.generateNewId()}>
            <Box textAlign={getTextAlign(index)}>
              {column}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    ));
  }, [filteredPopulationSummary, visualizeCategorical]);

  const getPopulationSummaryTable = useCallback(() => (
    <Table size="small">
      {getTrialArms()}

      <TableBody>
        {getCategoryHeader('Age at enrollment (years)')}
        {getRowsForCategory(filteredPopulationSummary.age, false)}

        {getCategoryHeader('Sex')}
        {getRowsForCategory(filteredPopulationSummary.sex, visualizeCategorical, 0)}

        {getCategoryHeader('Race')}
        {getRowsForCategory(filteredPopulationSummary.race, visualizeCategorical, 1)}

        {getCategoryHeader('Weight at enrollment (kg)')}
        {getRowsForCategory(filteredPopulationSummary.weight, false)}

        {getCategoryHeader('Smoking status')}
        {getRowsForCategory(filteredPopulationSummary.smoking_history, visualizeCategorical, 2)}

        {getCategoryHeader('Smoking exposure (years)')}
        {getRowsForCategory(filteredPopulationSummary.smoking_years, false)}

        {getCategoryHeader('ECOG performance')}
        {getRowsForCategory(filteredPopulationSummary.performance, visualizeCategorical, 3)}

        {getCategoryHeader('Disease stage at enrollment')}
        {getRowsForCategory(filteredPopulationSummary.disease_stage, visualizeCategorical, 4)}

        {getCategoryHeader('Number of prior lines')}
        {getRowsForCategory(filteredPopulationSummary.prior_line_number, visualizeCategorical, 5)}
      </TableBody>
    </Table>
  ), [filteredPopulationSummary, visualizeCategorical]);

  useEffect(() => {
    canvasParams.forEach((param) => {
      const c = document.getElementById(param.canvasId);
      const fillColor = fillColors[param.categoryIndex];
      const strokeColor = strokeColors[param.categoryIndex];
      if (c) {
        const ctx = c.getContext('2d');
        ctx.clearRect(0, 0, 50, canvasHeight);
        ctx.fillStyle = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.strokeRect(0, 0, canvasWeight, canvasHeight);
        ctx.fillRect(0, 0, param.pct, canvasHeight);
      }
    });
  }, [populationSummary, canvasParams]);

  return (
    <div>
      {populationFetched && (
        <Paper elevation={2} className={classes.modelRunDetailsPaper}>
          <Grid
            container
            direction="row"
            spacing={0}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    className={classes.componentCardTitle}
                  >
                    Population summary
                  </Typography>
                </Grid>

                <Grid item>
                  <Button onClick={() => topRef.current.scrollIntoView({ behavior: 'smooth' })}>
                    <ArrowUpwardIcon color="primary" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    color="primary"
                    checked={visualizeCategorical}
                    onChange={() => setVisualizeCategorical(!visualizeCategorical)}
                  />
                )}
                label="Visualize categorical variables"
              />
            </Grid>

            <Grid item xs={12}>
              {getPopulationSummaryTable()}
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
};

PopulationSummaryTable.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  populationSummary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  topRef: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(PopulationSummaryTable);
