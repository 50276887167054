import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Plot from 'react-plotly.js';
import styles from '../../style';
import { chartStyle, generateColors } from './charts';

const KeyQuantitiesChart = (props) => {
  const {
    keyQuantities,
    selectedMetric,
    selectedHistogramType,
    selectedInterval,
    selectedFaceted,
    runId,
  } = props;
  if (selectedMetric === '') return <div />;

  const keyQuantitiesPerMetric = keyQuantities.filter((k) => k.metric === selectedMetric)[0];
  const runIdShort = runId.slice(0, 4);

  const chartColors = ((selectedInterval !== 'none') || (!selectedFaceted)) ? generateColors(0.3) : generateColors(0.6);
  const labelColors = generateColors(1.0);
  const zeroLineColor = 'rgba(163, 158, 169, 0.2)';

  let overallMin = Number.MAX_SAFE_INTEGER;
  let overallMax = Number.MIN_SAFE_INTEGER;
  const data = [];
  const subplots = [];

  // get percentile interval for a given percentile
  const getPercentiles = (draws, pct) => {
    const sortedDraws = [...draws].sort((a, b) => a - b);
    const nPct = pct / 100;
    const medianIndex = Math.round(draws.length * 0.5) - 1;
    const lowIndex = Math.round(draws.length * (0.5 - (nPct / 2))) - 1;
    const highIndex = Math.round(draws.length * (0.5 + (nPct / 2))) - 1;
    return {
      median: sortedDraws[medianIndex],
      low: sortedDraws[lowIndex],
      high: sortedDraws[highIndex],
    };
  };

  // create traces
  keyQuantitiesPerMetric.data.forEach((el, index) => {
    const min = Math.min(...el.draws);
    const max = Math.max(...el.draws);
    if (min < overallMin) overallMin = min;
    if (max > overallMax) overallMax = max;
    const x = el.draws;
    const xaxis = (index === 0) ? 'x' : `x${index + 1}`;
    const yaxis = (index === 0) ? 'y' : `y${index + 1}`;
    const trace = {
      x,
      // xaxis,
      // yaxis,
      type: 'histogram',
      histnorm: selectedHistogramType,
      name: el.label,
      marker: {
        color: chartColors[index],
      },
    };
    if (selectedFaceted) {
      trace.xaxis = xaxis;
      trace.yaxis = yaxis;
      subplots.push([`${xaxis}${yaxis}`]);
    }
    data.push(trace);

    // add interval if enabled
    if ((selectedInterval !== 'none') && (selectedFaceted)) {
      const percentiles = getPercentiles(x, parseInt(selectedInterval, 10));
      const medianTrace = {
        x: [percentiles.median],
        y: [0],
        xaxis,
        yaxis,
        name: 'interval',
        mode: 'markers',
        showlegend: false,
        hoverinfo: 'x',
        marker: {
          color: labelColors[index],
          size: 10,
        },
      };
      const intervalTrace = {
        x: [percentiles.low, percentiles.high],
        y: [0, 0],
        xaxis,
        yaxis,
        name: 'interval',
        mode: 'lines',
        showlegend: false,
        hoverinfo: 'x',
        line: {
          color: labelColors[index],
          width: 3,
        },
      };
      data.push(intervalTrace);
      data.push(medianTrace);
    }
  });

  const layout = {
    title: {
      text: `${keyQuantitiesPerMetric.x_label} per ${keyQuantitiesPerMetric.y_label} (${selectedHistogramType})`,
    },
    autosize: true,
    // height: (350 - 20 * subplots.length) * subplots.length,
    height: 500,
    grid: {
      rows: subplots.length,
      columns: 1,
      pattern: 'independent',
      subplots,
    },
    annotations: [
      {
        xref: 'paper',
        yref: 'paper',
        text: `run id: ${runIdShort}`,
        x: 1.1,
        y: -0.05,
        showarrow: false,
        font: {
          size: 12,
          color: 'rgba(0, 0, 0, 0.3)',
        },
      },
    ],
  };

  // axis properties
  if (selectedFaceted) {
    layout.height = (450 - 20 * subplots.length) * subplots.length;

    const offset = (overallMax - overallMin) * 0.05;
    const medianIndex = Math.round(keyQuantitiesPerMetric.data.length * 0.5) - 1;
    keyQuantitiesPerMetric.data.forEach((el, index) => {
      const xAxis = (index === 0) ? 'xaxis' : `xaxis${index + 1}`;
      const xText = `${keyQuantitiesPerMetric.x_label} <b>(${el.label})</b>`;
      layout[xAxis] = {
        range: [overallMin - offset, overallMax + offset],
        hoverformat: '.2f',
        title: {
          text: xText,
          standoff: 10,
          font: {
            color: labelColors[index],
          },
        },
      };

      const yAxis = (index === 0) ? 'yaxis' : `yaxis${index + 1}`;
      const yText = selectedHistogramType.charAt(0).toUpperCase() + selectedHistogramType.slice(1);
      layout[yAxis] = {
        ticksuffix: (selectedHistogramType === 'percent') ? '%' : '',
        zeroline: true,
        zerolinecolor: zeroLineColor,
        title: {
          text: (medianIndex === index) ? yText : '',
        },
      };
    });
  } else {
    const offset = (overallMax - overallMin) * 0.05;
    const xAxis = 'xaxis';
    const xText = `${keyQuantitiesPerMetric.x_label}`;
    layout[xAxis] = {
      range: [overallMin - offset, overallMax + offset],
      title: {
        text: xText,
        standoff: 10,
      },
    };

    const yAxis = 'yaxis';
    const yText = selectedHistogramType.charAt(0).toUpperCase() + selectedHistogramType.slice(1);
    layout[yAxis] = {
      ticksuffix: (selectedHistogramType === 'percent') ? '%' : '',
      zeroline: true,
      zerolinecolor: zeroLineColor,
      title: {
        text: yText,
      },
    };
    layout.barmode = 'overlay';
  }

  const config = {
    toImageButtonOptions: {
      filename: `${selectedMetric}_${selectedHistogramType}_${runIdShort}`.replace(/( )+/g, '_'),
    },
  };

  return (
    <div>
      <Plot
        useResizeHandler
        style={chartStyle}
        layout={layout}
        data={data}
        config={config}
      />
    </div>
  );
};

KeyQuantitiesChart.propTypes = {
  keyQuantities: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedMetric: PropTypes.string.isRequired,
  selectedHistogramType: PropTypes.string.isRequired,
  selectedInterval: PropTypes.string.isRequired,
  selectedFaceted: PropTypes.bool.isRequired,
  runId: PropTypes.string.isRequired,
};

export default withStyles(styles)(KeyQuantitiesChart);
