import CategoricalScore from './CategoricalScore';
import PercentileScore from './PercentileScore';
import ProportionScore from './ProportionScores';

const createScore = (score, description) => {
  if (score === undefined) return null;

  switch (score.type) {
    case 'median_qi': {
      return new PercentileScore(score, description);
    }
    case 'categorical': {
      return new CategoricalScore(score, description);
    }
    case 'proportion':
    case 'float':
    case 'integer': {
      return new ProportionScore(score, description);
    }
    default: {
      return null;
    }
  }
};

const getScore = (scores, scoreName) => ((scores !== null)
  ? scores.filter((score) => score.name === scoreName)[0]
  : { type: null });

export {
  getScore,
  createScore,
};
