import React from 'react';
import { generateColors } from '../component/chart/charts';
import Score from './baseScore';

class ProportionScore extends Score {
  constructor(score, description) {
    super(score, description);

    const strokeColors = generateColors(Score.strokeAlpha);
    const fillColors = generateColors(Score.fillAlpha);

    this.strokeColor = strokeColors[2]; // eslint-disable-line prefer-destructuring
    this.fillColor = fillColors[2]; // eslint-disable-line prefer-destructuring
  }

  renderScoreComponent(ctx, canvasWidth, canvasHeight) {
    const startYOffset = 13;
    const endYOffset = 20;
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    ctx.fillStyle = this.fillColor;
    ctx.strokeStyle = this.strokeColor;
    ctx.strokeRect(0, startYOffset, canvasWidth, canvasHeight - endYOffset);
    const value = Score.convertToInterval(
      this.score.value, canvasWidth, this.score.expected_range[0], this.score.expected_range[1],
    );
    ctx.fillRect(0, startYOffset, value, canvasHeight - endYOffset);
  }

  getScoreToolTip() {
    let value;
    switch (this.score.type) {
      case 'float': {
        value = this.score.value.toFixed(2);
        break;
      }
      case 'integer': {
        value = this.score.value;
        break;
      }
      case 'proportion': {
        value = `${(this.score.value * 100).toFixed(2)}%`;
        break;
      }
      default: {
        throw new Error(`${this.score.type} is not a supported score type`);
      }
    }

    return (
      <div>
        {this.description}
        :
        &nbsp;
        {value}
      </div>
    );
  }
}

export default ProportionScore;
