import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from '../../style';
import CrmActions from './CrmActions';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import ActivityComponent from './ActivityComponent';
import CrmAction from '../../util/crmConstants';

const WebAppActivity = (props) => {
  const { classes, handleBreadcrumbs, isAdmin } = props;

  useEffect(() => {
    if (!isAdmin) return;
    handleBreadcrumbs(GecoBreadcrumbs.crmWebAppActivity());
  }, [handleBreadcrumbs, isAdmin]);

  if (!isAdmin) return <div />;

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <CrmActions action={CrmAction.WEB_ACTIVITY} />
      <Typography variant="h4">
        Web app activity
      </Typography>
      <ActivityComponent type="webapp" />
    </div>
  );
};

WebAppActivity.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default withStyles(styles)(WebAppActivity);
