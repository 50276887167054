import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Slide, Grid, TextField, Select, MenuItem, FormControl, InputLabel, IconButton,
  Typography, Button,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import styles from '../../style';

const ModelRunsFilters = (props) => {
  const {
    classes,
    modelRuns,
    onFilterChange,
    runId,
    description,
    seed,
    modelType,
    version,
  } = props;

  const [filtersOn, setFiltersOn] = useState(false);
  const [dataReady, setDataReady] = useState(false);

  const descriptionValues = (dataReady)
    ? [...new Set(modelRuns.run_list.map((v) => v.description))].sort((a, b) => a - b)
    : [];
  const seedValues = (dataReady)
    ? [...new Set(modelRuns.run_list.map((v) => v.seed_subjects))].sort((a, b) => a - b)
    : [];
  const typeValues = (dataReady)
    ? [...new Set(modelRuns.run_list.map((v) => v.type))].sort()
    : [];
  const versionValues = (dataReady)
    ? [...new Set(modelRuns.run_list.map((v) => v.version))].sort()
    : [];

  const clearFilters = () => {
    onFilterChange({
      name: 'runId',
      value: '',
    });
    onFilterChange({
      name: 'description',
      value: '',
    });
    onFilterChange({
      name: 'seed',
      value: 0,
    });
    onFilterChange({
      name: 'type',
      value: '',
    });
    onFilterChange({
      name: 'version',
      value: '',
    });
  };

  const onComponentChange = useCallback((event) => {
    onFilterChange({
      name: event.target.name,
      value: event.target.value,
    });
  }, [
    modelRuns,
    runId,
    description,
    seed,
    modelType,
    version,
  ]);

  const toggleFilters = () => {
    setFiltersOn(!filtersOn);
  };

  useEffect(() => {
    setDataReady(Object.keys(modelRuns).length !== 0);
  }, [modelRuns]);

  const filterIcon = (!filtersOn) ? <ExpandMoreIcon color="primary" /> : <ExpandLessIcon color="primary" />;

  return (
    <div className={classes.modelRunFilter}>
      {dataReady && (
        <>
          <Typography className={classes.modelRunFilterLabel}>
            Model run filters
            <IconButton onClick={toggleFilters}>
              {filterIcon}
            </IconButton>
          </Typography>
          <Slide direction="up" in={filtersOn} mountOnEnter unmountOnExit>
            <Paper elevation={2} className={classes.modelRunFilterPaper}>
              <form noValidate autoComplete="off">
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    <TextField
                      className={classes.formElement}
                      label="Id"
                      name="runId"
                      onChange={onComponentChange}
                      value={runId}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl>
                      <InputLabel>Description</InputLabel>
                      <Select
                        className={classes.formElement}
                        defaultValue=""
                        inputProps={{ name: 'description' }}
                        onChange={onComponentChange}
                        value={description}
                      >
                        <MenuItem value="" />
                        {descriptionValues.map((v) => (
                          <MenuItem value={v} key={v}>
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl>
                      <InputLabel>Seed</InputLabel>
                      <Select
                        defaultValue=""
                        inputProps={{ name: 'seed' }}
                        onChange={onComponentChange}
                        value={seed}
                      >
                        <MenuItem value={0} />
                        {seedValues.map((v) => (
                          <MenuItem value={v} key={v}>
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl>
                      <InputLabel>Type</InputLabel>
                      <Select
                        className={classes.formElement}
                        defaultValue=""
                        inputProps={{ name: 'type' }}
                        onChange={onComponentChange}
                        value={modelType}
                      >
                        <MenuItem value="" />
                        {typeValues.map((v) => (
                          <MenuItem value={v} key={v}>
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl>
                      <InputLabel>Version</InputLabel>
                      <Select
                        className={classes.formElement}
                        defaultValue=""
                        inputProps={{ name: 'version' }}
                        onChange={onComponentChange}
                        value={version}
                      >
                        <MenuItem value="" />
                        {versionValues.map((v) => (
                          <MenuItem value={v} key={v}>
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      color="primary"
                      fullWidth
                      onClick={clearFilters}
                      variant="outlined"
                    >
                      Clear all filters
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Slide>
        </>
      )}
    </div>
  );
};

ModelRunsFilters.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  modelRuns: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onFilterChange: PropTypes.func.isRequired,
  runId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  seed: PropTypes.number.isRequired,
  modelType: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export default withStyles(styles)(ModelRunsFilters);
