const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'rgba(255,255,255,0.0)',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuIconDark: {
    fill: '#ffffff',
    opacity: 0.5,
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  form: {
    maxWidth: 450,
    margin: 'auto',
    padding: '24px',
  },
  chartContainer: {
    marginLeft: -22,
  },
  formElement: {
    minWidth: 164,
    width: 250,
    textAlign: 'left',
    color: '#050540',
  },
  formElementWide: {
    minWidth: 164,
    width: 380,
    textAlign: 'left',
    color: '#050540',
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  componentLabel: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'left',
    paddingLeft: '0.2em',
  },
  drawerNestedItem: {
    paddingLeft: theme.spacing(3),
    textDecoration: 'none',
    color: '#050540',
  },
  componentHero: {
    height: '296px',
    width: '100%',
    background: '#ba7dff',
    backgroundImage: 'linear-gradient(to left, rgba(30, 11, 56, 0.7), rgba(30, 11, 56, 0.9) 600px, rgba(30, 11, 56, 1) 700px, #10061E 800px, #10061E 100%), url("/img/disease_areas_background.png")',
    backgroundSize: '100% 296px, auto 296px',
    backgroundPosition: 'right top, right top',
    backgroundRepeat: 'no-repeat, no-repeat',
  },
  componentTrial: {
    height: '226px',
    width: '100%',
    background: '#eff3f9',
  },
  componentTrialLabel: {
    marginBottom: '12px',
  },
  componentTrialVSpace: {
    marginBottom: '25px',
  },
  componentHeaderTitle: {
    padding: '12px',
  },
  componentTitle: {
    textAlign: 'left',
  },
  tabDescription: {
    textAlign: 'left',
    marginLeft: '-0.6em',
    marginBottom: '2em',
  },
  componentTitleDark: {
    textAlign: 'left',
    color: '#ffffff',
  },
  componentSubtitle: {
    textAlign: 'left',
    opacity: 0.5,
    paddingBottom: 29,
    fontSize: 14,
  },
  componentCardTitle: {
    marginTop: '20px',
    marginLeft: '20px',
    marginBottom: '20px',
    textAlign: 'left',
  },
  activityTableDiv: {
    margin: 'auto',
    minWidth: '300px',
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
  },
  componentSubtitleDark: {
    textAlign: 'left',
    opacity: 0.5,
    paddingBottom: 37,
    color: '#ffffff',
  },
  grow: {
    flexGrow: 1,
  },
  generableLogo: {
    height: 32,
    marginTop: 8,
    marginRight: 24,
  },
  generableLogoDark: {
    height: 32,
    marginTop: 8,
    marginRight: 24,
    color: '#fffffff',
  },
  diseaseAreaLabel: {
    fontWeight: 600,
    float: 'left',
    color: '#ffffff',
    fontSize: '1em',
    paddingBottom: 24,
  },
  userInfo: {
    width: 1150,
    marginLeft: 20,
  },
  lowPaddingRowColumn: {
    paddingLeft: 5,
    paddingRight: 5,
    width: 90,
  },
  modelRunFilter: {
    paddingBottom: 30,
  },
  modelRunFilterLabel: {
    marginLeft: 10,
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'left',
  },
  modelRunFilterPaper: {
    padding: 20,
  },
  modelRunDetailsPaper: {
    padding: 10,
  },
  versionCardProject: {
    textAlign: 'left',
    padding: '6px 16px',
  },
  versionCardInference: {
    textAlign: 'left',
    padding: '6px 16px',
    background: '#dfdfdf',
  },
  breadCrumbFocus: {
    color: '#000000',
  },
  padding: {
    padding: theme.spacing(3),
  },
  sideComponent: {
    marginTop: '25px',
  },
  side: {
    marginLeft: '2em',
    width: '100%',
  },
  sideTitle: {
    fontSize: '1em',
    fontWeight: 600,
    color: '#050540',
    paddingBottom: 12,
    textAlign: 'left',
  },
  sideSubtitle: {
    fontSize: '0.9em',
    fontWeight: 400,
    color: '#050540',
    paddingBottom: 12,
    marginTop: -12,
    textAlign: 'left',
  },
  sideCalibrationTitle: {
    fontSize: '1em',
    fontWeight: 600,
    color: '#050540',
    paddingBottom: 5,
    textAlign: 'left',
  },
  sideCalibrationLabel: {
    color: '#000000',
    fontSize: '2em',
    textAlign: 'left',
  },
  sideCohort: {
    width: '100%',
    textAlign: 'left',
  },
  sideTable: {
    margin: 'auto',
    minWidth: '300px',
    '& td': {
      textAlign: 'left',
      verticalAlign: 'top',
      paddingLeft: 0,
      color: '#7f7b84',
      borderBottomWidth: 0,
      paddingTop: 0,
      paddingBottom: 10,
    },
    '& td:first-child': {
      color: '#000000',
    },
  },
  sideTableTreatmentsList: {
    color: '#7f7b84 !important',
    paddingLeft: '2em !important',
  },
  sideTableRowSublabel: {
    paddingLeft: '1.5em !important',
  },
  sideTableRowPercent: {
    textAlign: 'right !important',
  },
  sideTableRowVspaceAbove: {
    paddingTop: '1em !important',
  },
  tabLevel2: {
    paddingTop: 15,
    marginLeft: 10,
    marginRight: 11,
    marginTop: 5,
    marginBottom: 7,
  },
  componentVspace: {
    paddingBottom: 32,
  },
  noUnderline: {
    textDecoration: 'none',
    color: '#000000',
  },
});

export default styles;
