import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Container, Grid, TextField, Typography, withStyles,
} from '@material-ui/core';
import axios from 'axios';
import styles from '../../style';
import DocsTable from '../table/DocsTable';
import ApiRouter from '../../util/ApiRouter';
import errorHandler from '../../util/requestError';
import { emptyArray } from '../../util/util';

const DocList = (props) => {
  const { classes, projectId, versionId } = props;

  const [docs, setDocs] = useState(emptyArray);
  const [filteredDocs, setFilteredDocs] = useState(emptyArray);

  const onSearchChange = (event) => {
    setFilteredDocs(
      docs.filter((doc) => doc.file_name.toLowerCase().includes(event.target.value.toLowerCase())),
    );
  };

  const fetchData = useCallback(() => {
    axios
      .get(ApiRouter.docsProjectVersion(projectId, versionId))
      .then((response) => {
        const sortedDocs = response.data.sort((a, b) => ((a.created_at <= b.created_at) ? 1 : -1));
        setDocs(sortedDocs);
        setFilteredDocs(sortedDocs);
      })
      .catch((error) => errorHandler(error));
  }, [projectId, versionId]);

  useEffect(() => { fetchData(); }, [fetchData]);

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item xs={12} className={classes.tabDescription}>
          <Typography variant="caption">
            Project documents generated from this version of the data. Click on the version id
            above to go to version picker to switch to a different version.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Search"
            onChange={onSearchChange}
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            disabled
          >
            New
          </Button>
        </Grid>
      </Grid>
      <DocsTable
        projectId={projectId}
        versionId={versionId}
        docs={filteredDocs}
      />
    </Container>
  );
};

DocList.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  projectId: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
};

export default withStyles(styles)(DocList);
