import React from 'react';
import { generateColors } from '../component/chart/charts';
import Score from './baseScore';

class PercentileScore extends Score {
  static color = generateColors(1.0)[1];

  static offset1 = 4;

  static radius = 5;

  renderScoreComponent(ctx, canvasWidth, canvasHeight) {
    const value = Score.convertToInterval(
      this.score.value,
      canvasWidth,
      this.score.expected_range[0],
      this.score.expected_range[1],
    );
    const rangeMin = Score.convertToInterval(
      this.score.range[0],
      canvasWidth,
      this.score.expected_range[0],
      this.score.expected_range[1],
    );
    const rangeMax = Score.convertToInterval(
      this.score.range[1],
      canvasWidth,
      this.score.expected_range[0],
      this.score.expected_range[1],
    );

    ctx.fillStyle = PercentileScore.color;
    ctx.strokeStyle = PercentileScore.color;
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    ctx.fillRect(
      rangeMin,
      canvasHeight / 2 - PercentileScore.offset1 / 2 + PercentileScore.offset1 / 2,
      rangeMax,
      PercentileScore.offset1,
    );
    ctx.beginPath();
    ctx.arc(
      value,
      canvasHeight / 2 + PercentileScore.offset1 / 2,
      PercentileScore.radius,
      0,
      2 * Math.PI,
    );
    ctx.fill();
  }

  getScoreToolTip() {
    return (
      <div>
        {this.description}
        :
        &nbsp;
        {this.score.value.toFixed(2)}
        &nbsp;
        [
        {this.score.range[0].toFixed(2)}
        ,
        &nbsp;
        {this.score.range[1].toFixed(2)}
        ]
      </div>
    );
  }
}

export default PercentileScore;
