import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonGroup, Grid, Tooltip, withStyles,
} from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import TableChartIcon from '@material-ui/icons/TableChart';
import EquilizerIcon from '@material-ui/icons/Equalizer';
import ShowChart from '@material-ui/icons/ShowChart';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import styles from '../../style';
import ModelRunFeatures from '../../util/modelRunFeatures';

const ModelRunNavigation = (props) => {
  const {
    formats,
    setFormats,
    runDescriptionRef,
    keyQuantitiesRef,
    keyQuantitiesOTRef,
    populationSummaryRef,
    showInfo,
    showKeyQuantities,
    showKeyQuantitiesOverTime,
    showPopulationSummary,
  } = props;

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <ToggleButtonGroup
            value={formats}
            onChange={(_, newFormats) => setFormats(newFormats)}
            style={{ height: '40px' }}
          >
            <ToggleButton value={ModelRunFeatures.runDescription}>
              <Tooltip title="Run description">
                <TableChartIcon />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value={ModelRunFeatures.keyQuantities}>
              <Tooltip title="Key quantities">
                <EquilizerIcon />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value={ModelRunFeatures.keyQuantitiesOverTime}>
              <Tooltip title="Key quantities over time">
                <ShowChart />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value={ModelRunFeatures.populationSummary}>
              <Tooltip title="Population summary">
                <ViewHeadline />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item>
          <ButtonGroup>
            {showInfo && (
              <Button onClick={() => runDescriptionRef.current.scrollIntoView({ behavior: 'smooth' })}>
                <TableChartIcon color="primary" />
              </Button>
            )}

            {showKeyQuantities && (
              <Button onClick={() => keyQuantitiesRef.current.scrollIntoView({ behavior: 'smooth' })}>
                <EquilizerIcon color="primary" />
              </Button>
            )}

            {showKeyQuantitiesOverTime && (
              <Button onClick={() => keyQuantitiesOTRef.current.scrollIntoView({ behavior: 'smooth' })}>
                <ShowChart color="primary" />
              </Button>
            )}

            {showPopulationSummary && (
              <Button onClick={() => populationSummaryRef.current.scrollIntoView({ behavior: 'smooth' })}>
                <ViewHeadline color="primary" />
              </Button>
            )}
          </ButtonGroup>
        </Grid>
      </Grid>

    </div>
  );
};

ModelRunNavigation.propTypes = {
  formats: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  setFormats: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  runDescriptionRef: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  keyQuantitiesRef: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  keyQuantitiesOTRef: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  populationSummaryRef: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  showInfo: PropTypes.bool.isRequired, // eslint-disable-line react/forbid-prop-types
  showKeyQuantities: PropTypes.bool.isRequired, // eslint-disable-line react/forbid-prop-types
  // eslint-disable-next-line
  showKeyQuantitiesOverTime: PropTypes.bool.isRequired, // eslint-disable-line react/forbid-prop-types
  showPopulationSummary: PropTypes.bool.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(ModelRunNavigation);
