class RandomIdCollection {
  constructor(ids) {
    if (ids !== undefined) { this.ids = ids; } else { this.ids = []; }
  }

  generateNewId() {
    const newId = Math.random().toString().slice(2);
    if (this.ids.indexOf(newId) !== -1) return this.generateNewId();
    this.ids.push(newId);
    return newId;
  }
}

export default RandomIdCollection;
