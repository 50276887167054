/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';
import styles from '../../style';
import AppHeader from '../common/AppHeader';

const ProjectLayout = withStyles(styles)(
  (props) => {
    const {
      children,
      classes,
      cohortsForTrial,
      match,
      projects,
      selectedCohortId,
      showValidation,
      tabLevel1,
    } = props;
    const { params } = match;
    const { projectId, versionId, time } = params;
    return (

      <div className={classes.componentTrial}>
        <div className={classes.appBarSpacer} />
        <AppHeader
          projectId={projectId}
          versionId={versionId}
          selectedTab={tabLevel1}
          showValidation={showValidation}
          selectedCohortId={selectedCohortId}
          cohortsForTrial={cohortsForTrial}
          time={time}
          projects={projects}
        />
        {children}
      </div>
    );
  },
);

const ProjectRoute = ({ component: Component, ...rest }) => {
  const [tabLevel1, setTabLevel1] = useState(1);
  const {
    cohortsForTrial,
    handleBreadcrumbs,
    projects,
    selectedCohortId,
    showValidation,
    tabLevel1Index,
  } = rest;

  useEffect(() => setTabLevel1(tabLevel1Index), [tabLevel1Index]);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <ProjectLayout
          match={matchProps.match}
          tabLevel1={tabLevel1}
          showValidation={showValidation}
          selectedCohortId={selectedCohortId}
          cohortsForTrial={cohortsForTrial}
          projects={projects}
        >
          <Component
            handleBreadcrumbs={handleBreadcrumbs}
            selectedCohortId={selectedCohortId}
            projects={projects}
            {...matchProps}
          />
        </ProjectLayout>
      )}
    />
  );
};

ProjectRoute.propTypes = {
  component: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ProjectRoute;
