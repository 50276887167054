import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, withStyles,
} from '@material-ui/core';
import axios from 'axios';
import styles from '../../style';
import CrmActions from './CrmActions';
import CrmAction from '../../util/crmConstants';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import ApiRouter from '../../util/ApiRouter';
import errorHandler from '../../util/requestError';
import { emptyArray } from '../../util/util';

const CrmCompany = (props) => {
  const { classes, handleBreadcrumbs, isAdmin } = props;
  const [companies, setCompanies] = useState(emptyArray);
  const [newCompany, setNewCompany] = useState('');

  const fetchCompanyData = useCallback(() => {
    axios
      .get(ApiRouter.crmGetCompanies())
      .then((response) => setCompanies(response.data))
      .catch((error) => errorHandler(error));
  }, [newCompany.length !== 0]);

  const addCompany = () => {
    axios
      .post(ApiRouter.crmAddCompany(), { name: newCompany })
      .then(() => setNewCompany(''))
      .catch((error) => errorHandler(error));
  };

  useEffect(() => {
    if (!isAdmin) return;
    handleBreadcrumbs(GecoBreadcrumbs.crmCompany());
    fetchCompanyData();
  }, [handleBreadcrumbs, fetchCompanyData, isAdmin]);

  if (!isAdmin) return <div />;

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <CrmActions action={CrmAction.COMPANY_MANAGEMENT} />
      <Typography variant="h4">
        Company management
      </Typography>

      <div className={classes.appBarSpacer} />

      <Grid
        container
        alignItems="flex-end"
        justifyContent="center"
        direction="row"
      >
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Created at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((company) => (
                <TableRow key={company.id}>
                  <TableCell>{company.id}</TableCell>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>{company.created_at}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={6}>
          <FormControl>
            <TextField
              label="New company name"
              value={newCompany}
              onChange={(event) => setNewCompany(event.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={1}>
          <FormControl>
            <Button
              color="primary"
              onClick={addCompany}
            >
              Add
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

CrmCompany.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CrmCompany);
