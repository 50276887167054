class Score {
  static fillAlpha = 0.9;

  static strokeAlpha = 0.9;

  constructor(score, description) {
    this.score = score;
    this.description = description;
  }

  static convertToInterval(x, width, start, end) {
    return (width / (end - start)) * (x - start);
  }

  renderScoreComponent() { throw new Error(`method not implemented for ${this.score.type}`); }

  getScoreToolTip() { throw new Error(`method not implemented for ${this.score.type}`); }
}

export default Score;
