import {
  Button,
  Container, Grid, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField,
  Typography, withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import React, { useEffect, useCallback, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import styles from '../../style';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import ApiRouter from '../../util/ApiRouter';
import errorHandler from '../../util/requestError';
import { emptyObject } from '../../util/util';

const UserSettings = (props) => {
  const { classes, handleBreadcrumbs } = props;

  const OLD_PASSWORD = 'oldPassword';
  const NEW_PASSWORD1 = 'newPassword1';
  const NEW_PASSWORD2 = 'newPassword2';
  const DIGITS_RE = /\d/g;
  const LOWERCASE_RE = /[a-z]/g;
  const UPPERCASE_RE = /[A-Z]/g;

  const [user, setUser] = useState(emptyObject);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [paswordButtonEnabled, setPasswordButtonEnabled] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('Password succesfully changed');
  const [snackStatus, setSnackStatus] = useState('success');

  const shouldShowPasswordButton = (val1, val2, val3) => {
    if (val1 !== '' && val2 !== '' && val3 !== '') {
      setPasswordButtonEnabled(true);
    } else {
      setPasswordButtonEnabled(false);
    }
  };

  const showPasswordButton = (currentId, currentValue) => {
    switch (currentId) {
      case OLD_PASSWORD:
        shouldShowPasswordButton(currentValue, newPassword1, newPassword2);
        break;
      case NEW_PASSWORD1:
        shouldShowPasswordButton(oldPassword, currentValue, newPassword2);
        break;
      case NEW_PASSWORD2:
        shouldShowPasswordButton(oldPassword, newPassword1, currentValue);
        break;
      default:
        break;
    }
  };

  const resetPasswordFields = () => {
    setOldPassword('');
    setNewPassword1('');
    setNewPassword2('');
  };

  const validateNewPassword = () => {
    if (newPassword1 !== newPassword2) {
      setSnackMessage('New password 1 and new password 2 do not match');
      setSnackOpen(true);
      setSnackStatus('error');
      resetPasswordFields();
      return false;
    }
    if (newPassword1.length < 8
      || !DIGITS_RE.test(newPassword1)
      || !LOWERCASE_RE.test(newPassword1)
      || !UPPERCASE_RE.test(newPassword1)
    ) {
      setSnackMessage('New password must be at least 8 characters long, contain lower case letter and upper case letter');
      setSnackOpen(true);
      setSnackStatus('error');
      resetPasswordFields();
      return false;
    }
    return true;
  };

  const changePassword = (event) => {
    if (validateNewPassword()) {
      const data = {
        old_password: oldPassword,
        new_password: newPassword1,
      };
      axios
        .post(ApiRouter.setPassword(), data)
        .then((response) => {
          if (response.data.message === 'Password successfully changed') {
            setSnackStatus('success');
          } else {
            setSnackStatus('error');
          }
          setSnackMessage(response.data.message);
          setSnackOpen(true);
          resetPasswordFields();
        })
        .catch((error) => errorHandler(error));
    }
    event.preventDefault();
  };

  const handleTextFieldChange = (event) => {
    showPasswordButton(event.target.id, event.target.value);
    switch (event.target.id) {
      case OLD_PASSWORD:
        setOldPassword(event.target.value);
        break;
      case NEW_PASSWORD1:
        setNewPassword1(event.target.value);
        break;
      case NEW_PASSWORD2:
        setNewPassword2(event.target.value);
        break;
      default:
        break;
    }
  };

  const fetchUserInfo = useCallback(() => {
    axios
      .get(ApiRouter.getUserInfo())
      .then((response) => setUser(response.data))
      .catch((error) => errorHandler(error));
  }, []);

  useEffect(() => {
    handleBreadcrumbs(GecoBreadcrumbs.userSettings());
    fetchUserInfo();
  }, [handleBreadcrumbs, fetchUserInfo]);

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container>
        <Typography variant="h3">
          User settings
        </Typography>
        <div className={classes.appBarSpacer} />
        <Grid
          container
          spacing={10}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="left"
            >
              User Info
            </Typography>
            <Table className={classes.userInfo}>
              <TableHead>
                <TableRow>
                  <TableCell>Attribute</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>email</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created at</TableCell>
                  <TableCell>{user.created_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Updated at</TableCell>
                  <TableCell>{user.updated_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>{user.company}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Projects</TableCell>
                  <TableCell>{(user.projects !== undefined) ? user.projects.join(', ') : ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" align="left">
              Change password
            </Typography>
            <form noValidate>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={3}>
                  <TextField
                    id={OLD_PASSWORD}
                    label="Old password"
                    type="password"
                    onChange={handleTextFieldChange}
                    value={oldPassword}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={NEW_PASSWORD1}
                    label="New password 1"
                    type="password"
                    value={newPassword1}
                    onChange={handleTextFieldChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={NEW_PASSWORD2}
                    label="New password 2"
                    type="password"
                    value={newPassword2}
                    onChange={handleTextFieldChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={changePassword}
                    disabled={!paswordButtonEnabled}
                  >
                    Change password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid item xs={12}>
            <Snackbar open={snackOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
              <Alert severity={snackStatus} onClose={() => setSnackOpen(false)}>
                {snackMessage}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

UserSettings.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
};

export default withStyles(styles)(UserSettings);
