import TabActivityStatus from './TabActivityStatus';

class RunsActivityStatus extends TabActivityStatus {
  static RUNS_LIST = 'runs_list';

  static RUNS_RUN_DETAILS = 'runs_run_details';

  get all() {
    return TabActivityStatus.getTopLevelStatus(this.list);
  }

  get list() {
    return this.getActivityStatus(RunsActivityStatus.RUNS_LIST);
  }

  get runDetails() {
    return this.getActivityStatus(RunsActivityStatus.RUNS_RUN_DETAILS);
  }
}

export default RunsActivityStatus;
