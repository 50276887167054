import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, MenuItem, Select, withStyles,
} from '@material-ui/core';
import styles from '../../style';

const TrialSelector = (props) => {
  const {
    classes, trials, selectedTrial, onSelectedTrialChange,
  } = props;

  return (
    <div>
      <FormControl>
        <InputLabel>Trial</InputLabel>
        <Select
          className={classes.formElement}
          defaultValue={trials[0]}
          onChange={(event) => onSelectedTrialChange(event)}
          value={selectedTrial}
        >
          {trials.map((v) => (
            <MenuItem value={v} key={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

TrialSelector.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  trials: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedTrial: PropTypes.string.isRequired,
  onSelectedTrialChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(TrialSelector);
