import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid, TextField, Typography, withStyles,
} from '@material-ui/core';
import axios from 'axios';
import styles from '../../style';
import CrmActions from './CrmActions';
import CrmAction from '../../util/crmConstants';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import ApiRouter from '../../util/ApiRouter';
import errorHandler from '../../util/requestError';

const CrmModelRun = (props) => {
  const { classes, handleBreadcrumbs, isAdmin } = props;
  const [tagAssign, setTagAssign] = useState('');
  const [versionAssign, setVersionAssign] = useState('');
  const [modelRunIdsAssign, setModelRunIdsAssign] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [tagRemove, setTagRemove] = useState('');
  const [versionRemove, setVersionRemove] = useState('');

  const assignTag = useCallback(() => {
    const data = {
      tag: tagAssign,
      version: versionAssign,
      model_run_ids: modelRunIdsAssign,
    };
    axios
      .post(ApiRouter.crmAssignTag(), data)
      .then((response) => {
        if (response.data !== null) setErrorMsg(response.data.error);
      })
      .catch((error) => errorHandler(error));
    setTagAssign('');
    setVersionAssign('');
    setModelRunIdsAssign('');
    setErrorMsg('');
  }, [tagAssign, versionAssign, modelRunIdsAssign]);

  const removeTag = useCallback(() => {
    const data = {
      tag: tagRemove,
      version: versionRemove,
    };
    axios
      .post(ApiRouter.crmRemoveTag(), data)
      .then((response) => {
        if (response.data !== null) setErrorMsg(response.data.error);
      })
      .catch((error) => errorHandler(error));
    setTagRemove('');
    setVersionRemove('');
    setErrorMsg('');
  }, [tagRemove, versionRemove]);

  useEffect(() => {
    if (!isAdmin) return;
    handleBreadcrumbs(GecoBreadcrumbs.crmModelRun());
  }, [handleBreadcrumbs, isAdmin]);

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />

      <CrmActions action={CrmAction.MODELRUN_MANAGEMENT} />
      <Typography variant="h4">
        Model run management
      </Typography>

      <div className={classes.appBarSpacer} />
      <Grid
        container
        alignItems="flex-end"
        justifyContent="center"
        direction="row"
      >
        <Grid item xs={12}>
          <Typography variant="h5">
            Add tags
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Tag"
            value={tagAssign}
            onChange={(event) => setTagAssign(event.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Project version id"
            value={versionAssign}
            onChange={(event) => setVersionAssign(event.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Model run ids"
            multiline
            value={modelRunIdsAssign}
            onChange={(event) => setModelRunIdsAssign(event.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={assignTag}
            disabled={(tagAssign === '') || (versionAssign === '') || (modelRunIdsAssign === '')}
          >
            Assign
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">
            <div className={classes.appBarSpacer} />
            Remove tags
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Tag"
            value={tagRemove}
            onChange={(event) => setTagRemove(event.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Project version id"
            value={versionRemove}
            onChange={(event) => setVersionRemove(event.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={removeTag}
            disabled={(tagRemove === '') || (versionRemove === '')}
          >
            Remove
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography color="secondary" variant="h6">
            {errorMsg}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

CrmModelRun.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CrmModelRun);
