import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Table, TableCell, TableBody, TableHead, TableRow, Typography, withStyles, Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import axios from 'axios';
import styles from '../../style';
import CrmActions from './CrmActions';
import CrmAction from '../../util/crmConstants';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import ApiRouter from '../../util/ApiRouter';
import errorHandler from '../../util/requestError';
import { emptyArray, emptyObject } from '../../util/util';

const CrmProject = (props) => {
  const { classes, handleBreadcrumbs, isAdmin } = props;
  const [projects, setProjects] = useState(emptyObject);
  const [selectedConfigurations, setSelectedConfigurations] = useState(emptyArray);

  const fetchProjectData = useCallback(() => {
    axios
      .get(ApiRouter.crmGetProjects())
      .then((response) => {
        setProjects(response.data);
        const defaults = response.data.projects.map((project) => (
          {
            id: project.id,
            configuration_id: project.configuration_id,
          }));
        setSelectedConfigurations(defaults);
      })
      .catch((error) => errorHandler(error));
  }, []);

  const onConfigurationChange = useCallback((event) => {
    const projectId = event.target.name;
    const configurationId = event.target.value;
    const copy = [...selectedConfigurations];
    const configuration = copy.find((el) => el.id === projectId);
    configuration.configuration_id = configurationId;
    setSelectedConfigurations(copy);
  }, [selectedConfigurations]);

  const onSaveConfigurations = useCallback(() => {
    axios
      .post(ApiRouter.crmSaveProjectConfigurations(), { configurations: selectedConfigurations })
      .catch((error) => errorHandler(error));
  }, [selectedConfigurations]);

  useEffect(() => {
    if (!isAdmin) return;
    handleBreadcrumbs(GecoBreadcrumbs.crmProject());
    fetchProjectData();
  }, [handleBreadcrumbs, fetchProjectData, isAdmin]);

  if (!isAdmin) return <div />;

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <CrmActions action={CrmAction.PROJECT_MANAGEMENT} />
      <Typography variant="h4">
        Project management
      </Typography>

      <div className={classes.appBarSpacer} />

      <Container>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Project configuration</TableCell>
              <TableCell>Tools</TableCell>
            </TableRow>
          </TableHead>
          {(Object.keys(projects).length !== 0) && (selectedConfigurations.length !== 0) && (
            <TableBody>
              {projects.projects.map((project) => {
                const selectedConfigurationId = selectedConfigurations.filter(
                  (c) => c.id === project.id,
                )[0].configuration_id;
                const t = projects.configurations.find((c) => c.id === project.configuration_id);
                const toolsString = (t !== undefined) ? t.tools.join(', ') : '';

                return (
                  <TableRow key={project.id}>
                    <TableCell>{project.id}</TableCell>
                    <TableCell>{project.created_at}</TableCell>
                    <TableCell>
                      <Select
                        value={selectedConfigurationId}
                        onChange={onConfigurationChange}
                        inputProps={{ name: project.id }}
                      >
                        {projects.configurations.map((configuration) => (
                          <MenuItem key={configuration.id} value={configuration.id}>
                            {configuration.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>{toolsString}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        <div>
          <Button color="primary" variant="contained" onClick={onSaveConfigurations}>
            Save
          </Button>
        </div>
      </Container>

    </div>
  );
};

CrmProject.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CrmProject);
