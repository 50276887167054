import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField,
  Typography, withStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import styles from '../../style';
import CrmActions from './CrmActions';
import CrmAction from '../../util/crmConstants';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import ApiRouter from '../../util/ApiRouter';
import errorHandler from '../../util/requestError';
import { emptyArray } from '../../util/util';

const CrmUser = (props) => {
  const { classes, handleBreadcrumbs, isAdmin } = props;
  const [users, setUsers] = useState(emptyArray);
  const [companies, setCompanies] = useState(emptyArray);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  const [projects, setProjects] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isNewUser, setIsNewUser] = useState(true);

  const fetchUserData = useCallback(() => {
    axios
      .get(ApiRouter.crmGetUsers())
      .then((response) => {
        setUsers(response.data.users);
        setCompanies(response.data.companies);
      })
      .catch((error) => errorHandler(error));
  }, [email.length !== 0]);

  const resetFields = () => {
    setEmail('');
    setPassword('');
    setCompany('');
    setProjects('');
    setErrorMsg('');
    setIsNewUser(true);
  };

  const onSaveUpdateUser = useCallback(() => {
    const data = {
      isNewUser,
      email,
      password,
      company,
      projects,
    };
    axios
      .post(ApiRouter.crmAddUser(), data)
      .then((response) => {
        if (response.data !== null) {
          setErrorMsg(response.data.error);
        } else {
          resetFields();
        }
      })
      .catch((error) => errorHandler(error));
  }, [email, password, company, projects]);

  const onDeactivateUser = useCallback(() => {
    const data = { email };
    axios
      .post(ApiRouter.crmDeactivateUser(), data)
      .catch((error) => errorHandler(error));
  }, [email]);

  const onClearUser = useCallback(() => resetFields(), []);

  const onEdit = useCallback((userId, newEmail, newCompany, newProjects) => {
    setEmail(newEmail);
    setCompany(newCompany);
    setProjects(newProjects);
    setIsNewUser(false);
  }, []);

  useEffect(() => {
    if (!isAdmin) return;
    handleBreadcrumbs(GecoBreadcrumbs.crmUser());
    fetchUserData();
  }, [handleBreadcrumbs, fetchUserData, isAdmin]);

  if (!isAdmin) return <div />;

  return (
    <div className={classes.content}>
      <div className={classes.appBarSpacer} />
      <CrmActions action={CrmAction.USER_MANAGEMENT} />
      <Typography variant="h4">
        User management
      </Typography>

      <div className={classes.appBarSpacer} />
      <Grid
        container
        alignItems="flex-end"
        justifyContent="center"
        direction="row"
      >
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  email
                </TableCell>
                <TableCell>
                  Company
                </TableCell>
                <TableCell>
                  Projects
                </TableCell>
                <TableCell>
                  Disabled
                </TableCell>
                <TableCell>
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.company}</TableCell>
                  <TableCell>{user.projects.join(', ')}</TableCell>
                  <TableCell>{(user.disabled) ? 'Disabled' : 'Active'}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => onEdit(user.id, user.email, user.company, user.projects.join(', '))}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            alignItems="flex-end"
            justifyContent="center"
            direction="row"
            spacing={2}
          >
            <Grid item xs={12}>
              <FormControl>
                <TextField
                  label="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  disabled={!isNewUser}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <TextField
                  label="Password"
                  value={password}
                  disabled={!isNewUser}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {companies.length !== 0 && (
                <FormControl>
                  <Select
                    value={company}
                    disabled={!isNewUser}
                    onChange={(event) => setCompany(event.target.value)}
                  >
                    {companies.map((c) => (
                      <MenuItem key={c.id} value={c.name}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <TextField
                  label="Projects"
                  value={projects}
                  onChange={(event) => setProjects(event.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <Button color="primary" variant="contained" onClick={onSaveUpdateUser}>
                {isNewUser ? 'Save' : 'Update'}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="secondary"
                variant="contained"
                onClick={onDeactivateUser}
                disabled={isNewUser}
              >
                Deactivate user
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button color="primary" variant="contained" onClick={onClearUser}>
                Clear
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography color="error">
                {errorMsg}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
};

CrmUser.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CrmUser);
