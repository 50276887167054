import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Paper, Typography, withStyles, Button,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import styles from '../../style';
import KeyQuantitiesChart from '../chart/KeyQuantitiesChart';
import KeyQuantitiesMetricSelector from './KeyQuantitiesSelector';

const KeyQuantities = (props) => {
  const {
    classes, info, runId, topRef,
  } = props;
  const keyQuantities = info.key_quantities;
  const dataReady = ((keyQuantities !== undefined) && (keyQuantities !== null));
  const metrics = (dataReady) ? keyQuantities.map((kq) => kq.metric) : [];

  const [selectedMetric, setSelectedMetric] = useState('');
  const [selectedHistogramType, setSelectedHistogramType] = useState('percent');
  const [selectedInterval, setSelectedInterval] = useState('none');
  const [selectedFaceted, setSelectedFaceted] = useState(true);

  const onMetricChange = (v) => {
    if (v.target.name === 'metric') setSelectedMetric(v.target.value);
    if (v.target.name === 'histogram') setSelectedHistogramType(v.target.value);
    if (v.target.name === 'interval') setSelectedInterval(v.target.value);
  };

  useEffect(() => {
    if (metrics.length !== 0) setSelectedMetric(metrics[0]);
  }, [metrics.length]);

  return (
    <div>
      {dataReady && (
        <Paper elevation={2}>
          <Grid
            container
            direction="row"
            spacing={0}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    className={classes.componentCardTitle}
                  >
                    Key quantities
                  </Typography>
                </Grid>

                <Grid item>
                  <Button onClick={() => topRef.current.scrollIntoView({ behavior: 'smooth' })}>
                    <ArrowUpwardIcon color="primary" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <KeyQuantitiesMetricSelector
                metrics={metrics}
                selectedMetric={selectedMetric}
                selectedHistogramType={selectedHistogramType}
                selectedInterval={selectedInterval}
                onMetricChange={onMetricChange}
                selectedFaceted={selectedFaceted}
                setSelectedFaceted={setSelectedFaceted}
              />
            </Grid>

            <Grid item xs={12}>
              <KeyQuantitiesChart
                keyQuantities={keyQuantities}
                selectedMetric={selectedMetric}
                selectedHistogramType={selectedHistogramType}
                selectedInterval={selectedInterval}
                runId={runId}
                selectedFaceted={selectedFaceted}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
};

KeyQuantities.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  info: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  runId: PropTypes.string.isRequired,
  topRef: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(KeyQuantities);
