import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  withStyles, TextField, Button, CssBaseline, Paper, Grid, Container,
} from '@material-ui/core';
import errorHandler from '../../util/requestError';
import styles from '../../style';
import ApiRouter from '../../util/ApiRouter';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
    };
    this.handleTextInput = this.handleTextInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTextInput(event) {
    const newValue = {};
    newValue[event.target.name] = event.target.value;
    this.setState(newValue);
  }

  handleSubmit(event) {
    const { state } = this;
    const data = {
      email: state.email,
      password: state.password,
    };
    axios
      .post(ApiRouter.login(), data)
      .then((response) => {
        if (Object.keys(response.data).length) {
          this.setState({
            error: response.data.error,
            password: '',
          });
        } else {
          /* eslint-env browser */
          window.location.reload();
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
    event.preventDefault();
  }

  render() {
    const { props, state } = this;
    const { classes } = props;

    return (
      <Container maxWidth="lg">
        <CssBaseline />
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={6}>
            <Paper className={classes.card}>
              <div className={classes.form}>
                <img
                  src="/img/logo.svg"
                  className={classes.generableLogo}
                  alt="Generable"
                  align="left"
                />
                <form noValidate>
                  <TextField
                    autoComplete="email"
                    autoFocus
                    fullWidth
                    id="email"
                    label="Email"
                    margin="normal"
                    name="email"
                    onChange={this.handleTextInput}
                    value={state.email}
                    variant="outlined"
                  />
                  <TextField
                    autoComplete="password"
                    fullWidth
                    id="password"
                    label="Password"
                    margin="normal"
                    name="password"
                    onChange={this.handleTextInput}
                    type="password"
                    value={state.password}
                    variant="outlined"
                  />
                  <div className={classes.error}>
                    {state.error}
                    &nbsp;
                  </div>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={this.handleSubmit}
                    type="submit"
                    variant="contained"
                    style={{ marginTop: '24px' }}
                  >
                    Log in
                  </Button>
                </form>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(Login);
