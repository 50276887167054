import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container, Grid, Typography, withStyles,
} from '@material-ui/core';
import axios from 'axios';
import styles from '../../style';
import RunSelector from '../common/RunSelector';
import ApiRouter from '../../util/ApiRouter';
import PopulationSummaryTable from '../table/PopulationSummaryTable';
import { emptyArray, emptyObject } from '../../util/util';
import ModelRunInfoTable from '../table/ModelRunInfoTable';
import KeyQuantities from '../common/KeyQuantities';
import KeyQuantitiesOverTime from '../common/KeyQuantitiesOverTime';
import ModelRunNavigation from '../common/ModelRunNavigation';
import ModelRunFeatures from '../../util/modelRunFeatures';

const RunDetails = (props) => {
  const { classes, projectId, versionId } = props;
  const [runId, setRunId] = useState('');
  const [populationSummary, setPopulationSummary] = useState(emptyObject);
  const [selectedTrial, setSelectedTrial] = useState('');
  const [trials, setTrials] = useState(emptyArray);
  const [info, setInfo] = useState(emptyObject);
  const [selectorMsg, setSelectorMsg] = useState('');
  const [formats, setFormats] = useState([
    ModelRunFeatures.runDescription,
    ModelRunFeatures.keyQuantities,
    ModelRunFeatures.keyQuantitiesOverTime,
    ModelRunFeatures.populationSummary,
  ]);
  const topRef = useRef(null);
  const runDescriptionRef = useRef(null);
  const keyQuantitiesRef = useRef(null);
  const keyQuantitiesOTRef = useRef(null);
  const populationSummaryRef = useRef(null);

  const uuidLength = 36;

  const showInfo = formats.includes(ModelRunFeatures.runDescription);
  const showKeyQuantities = formats.includes(ModelRunFeatures.keyQuantities);
  const showKeyQuantitiesOverTime = formats.includes(ModelRunFeatures.keyQuantitiesOverTime);
  const showPopulationSummary = formats.includes(ModelRunFeatures.populationSummary);

  const dataLoaded = Object.keys(info).length !== 0;

  const fetchData = useCallback(() => {
    axios
      .get(ApiRouter.runDetails(projectId, versionId, runId, selectedTrial))
      .then((response) => {
        if (selectedTrial === '') setSelectedTrial(response.data.population_summary.trials[0]);
        setPopulationSummary(response.data.population_summary);
        setSelectorMsg('');
      })
      .catch(() => {
        setPopulationSummary(emptyObject);
        setSelectedTrial('');
        setInfo(emptyObject);
        setSelectorMsg(`${runId} is not a valid run id. Please select valid run id`);
      });
  }, [runId, selectedTrial]);

  const fetchTrials = useCallback((newRunId) => {
    axios
      .get(ApiRouter.runDetailsInfo(projectId, versionId, newRunId))
      .then((response) => {
        const trialName = response.data.trials[0];
        setTrials(response.data.trials);
        setSelectedTrial(trialName);
        setInfo(response.data);
      })
      .catch(() => {
        setSelectorMsg(`${newRunId} is not a valid run id. Please select valid run id`);
      });
  }, [runId]);

  const onModelRunIdChange = useCallback((event) => {
    setRunId(event.target.value);
    if (event.target.value.length === uuidLength) {
      fetchTrials(event.target.value);
      return;
    }
    setPopulationSummary(emptyObject);
  }, [runId, selectedTrial]);

  const onSelectedTrialChange = useCallback((event) => {
    setSelectedTrial(event.target.value);
  }, [runId, selectedTrial]);

  const onClearRunId = useCallback(() => {
    setRunId('');
    setPopulationSummary(emptyObject);
    setSelectorMsg('');
    setSelectedTrial('');
    setInfo(emptyObject);
  }, [runId, populationSummary, selectorMsg]);

  useEffect(() => {
    if (runId !== '' && selectedTrial !== '') fetchData();
  }, [runId, selectedTrial]);

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12} className={classes.tabDescription}>
          <Typography variant="caption">
            A run is an instance of a statistical model trained with a specific dataset like a
            clinical trial, or multiple trials. Run details include key attributes of a run
            including the description of the population used to train the model, selected
            inferences, and predictions.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <RunSelector
            runId={runId}
            onModelRunIdChange={onModelRunIdChange}
            onClearRunId={onClearRunId}
            trials={trials}
            selectedTrial={selectedTrial}
            onSelectedTrialChange={onSelectedTrialChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="caption"
            color="secondary"
          >
            {selectorMsg}
          </Typography>
        </Grid>

        {dataLoaded && (
          <Grid item xs={12} ref={topRef}>
            <ModelRunNavigation
              formats={formats}
              setFormats={setFormats}
              runDescriptionRef={runDescriptionRef}
              keyQuantitiesRef={keyQuantitiesRef}
              keyQuantitiesOTRef={keyQuantitiesOTRef}
              populationSummaryRef={populationSummaryRef}
              showInfo={showInfo}
              showKeyQuantities={showKeyQuantities}
              showKeyQuantitiesOverTime={showKeyQuantitiesOverTime}
              showPopulationSummary={showPopulationSummary}
            />
          </Grid>
        )}

        {showInfo && (
          <Grid item xs={12} ref={runDescriptionRef}>
            <ModelRunInfoTable
              data={info}
              projectId={projectId}
              topRef={topRef}
            />
          </Grid>
        )}

        {showKeyQuantities && (
          <Grid item xs={12} ref={keyQuantitiesRef}>
            <KeyQuantities
              info={info}
              runId={runId}
              topRef={topRef}
            />
          </Grid>
        )}

        {showKeyQuantitiesOverTime && (
          <Grid item xs={12} ref={keyQuantitiesOTRef}>
            <KeyQuantitiesOverTime
              info={info}
              runId={runId}
              topRef={topRef}
            />
          </Grid>
        )}

        {showPopulationSummary && (
          <Grid item xs={12} ref={populationSummaryRef}>
            <PopulationSummaryTable
              populationSummary={populationSummary}
              selectedTrial={selectedTrial}
              setSelectedTrial={setSelectedTrial}
              onSelectedTrialChange={onSelectedTrialChange}
              topRef={topRef}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

RunDetails.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  projectId: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
};

export default withStyles(styles)(RunDetails);
