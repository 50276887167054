import GecoRouter from './GecoRouter';

class GecoBreadcrumbs {
  static projectsList() {
    return [{ label: 'Projects List', link: GecoRouter.projectsList() }];
  }

  static docsTab(projectId, versionId) {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: `Project ${projectId}`, link: GecoRouter.docsTab(projectId, versionId) },
    ];
  }

  static diseaseArea(shortName) {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: shortName, link: GecoRouter.diseaseArea(shortName) }];
  }

  static projectVersions(shortName) {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: 'Versions', link: GecoRouter.projectVersions(shortName) }];
  }

  static userSettings() {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: 'User Settings', link: GecoRouter.userSettings() },
    ];
  }

  static crmWebAppActivity() {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: 'Web app activity', link: GecoRouter.crmWebAppActivity() },
    ];
  }

  static crmApiActivity() {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: 'API activity', link: GecoRouter.crmApiActivity() },
    ];
  }

  static crmActivityByDays(days) {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: 'Activity by days', link: GecoRouter.crmActivityByDays(days) },
    ];
  }

  static crmCompany() {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: 'Company', link: GecoRouter.crmCompany() },
    ];
  }

  static crmModelRun() {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: 'Model run', link: GecoRouter.crmModelRun() },
    ];
  }

  static crmProject() {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: 'Project', link: GecoRouter.crmProject() },
    ];
  }

  static crmUser() {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: 'User', link: GecoRouter.crmUser() },
    ];
  }

  static diseaseAreaInfo(shortName) {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: shortName, link: GecoRouter.diseaseArea(shortName) },
      { label: 'Information', link: GecoRouter.diseaseAreaInfo(shortName) }];
  }

  static trialDetails(shortName, trialName) {
    return [
      { label: 'Projects List', link: GecoRouter.projectsList() },
      { label: shortName, link: GecoRouter.diseaseArea(shortName) },
      { label: trialName, link: GecoRouter.trialDetails(shortName, trialName) },
    ];
  }
}

export default GecoBreadcrumbs;
