import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, withStyles,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import styles from '../../style';
import { createScore } from '../../util/modelRunScores';
import renderTag from '../../util/modelRunTags';
import RandomIdCollection from '../../util/RandomIdCollection';

const ModelRunInfoTable = (props) => {
  const {
    classes, data, projectId, topRef,
  } = props;

  const info = (Object.keys(data).length !== 0) ? data.info : {};
  const scoreDescriptions = (Object.keys(data).length !== 0)
    ? data.score_details.score_descriptions[projectId]
    : {};

  const infoFetched = Object.keys(info).length !== 0;
  const scoreParams = [];
  const canvasWidth = 500;
  const canvasHeight = 30;
  const scores = (info.scores !== null) ? info.scores : [];

  const idGenerator = new RandomIdCollection();

  useEffect(() => {
    scoreParams.forEach((scoreParam) => {
      if (scoreParam) {
        // eslint-disable-next-line
        const c = document.getElementById(scoreParam.score.name);
        const ctx = c.getContext('2d');
        scoreParam.renderScoreComponent(ctx, canvasWidth, canvasHeight);
      }
    });
  }, [scoreParams]);

  return (
    <div>
      {infoFetched && (
        <Paper elevation={2} className={classes.modelRunDetailsPaper}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    className={classes.componentCardTitle}
                  >
                    Run description
                  </Typography>
                </Grid>

                <Grid item>
                  <Button onClick={() => topRef.current.scrollIntoView({ behavior: 'smooth' })}>
                    <ArrowUpwardIcon color="primary" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Attribute</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow hover>
                    <TableCell>Id</TableCell>
                    <TableCell>{info.run_id}</TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>Description</TableCell>
                    <TableCell>{info.description}</TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>The seed that identifies unique set of subjects</TableCell>
                    <TableCell>{info.seed_subjects}</TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>Model type</TableCell>
                    <TableCell>{info.type}</TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>Model version</TableCell>
                    <TableCell>{info.version}</TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>Run start time</TableCell>
                    <TableCell>{info.run_started_on}</TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell>Model run tags</TableCell>
                    <TableCell>
                      <Grid container direction="row" alignItems="center">
                        {(info.tags !== null) && info.tags.map((tag, index) => (
                          <Grid item xs={1} key={idGenerator.generateNewId()}>
                            {renderTag(tag, index)}
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                  </TableRow>

                  {scores.map((score) => {
                    const scoreDescription = scoreDescriptions[score.name];
                    const s = createScore(score, scoreDescription);
                    scoreParams.push(s);

                    return s && (
                      <TableRow hover key={score.name}>
                        <TableCell>
                          {score.name}
                        </TableCell>
                        <Tooltip title={s.getScoreToolTip()}>
                          <TableCell>
                            <canvas id={score.name} width={canvasWidth} height={canvasHeight} />
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
};

ModelRunInfoTable.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  projectId: PropTypes.string.isRequired,
  topRef: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(ModelRunInfoTable);
