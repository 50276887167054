import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import styles from '../../style';
import ApiRouter from '../../util/ApiRouter';
import ActivityTable from './ActivityTable';
import { emptyArray, emptyObject } from '../../util/util';

const ActivityComponent = (props) => {
  const { type } = props;
  const [activity, setActivity] = useState(emptyArray);
  const [info, setInfo] = useState(emptyObject);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [showExternalOnly, setShowExternalOnly] = useState(true);

  const fetchActivityData = useCallback(() => {
    axios
      .get(ApiRouter.getWebActivity(type, showExternalOnly, currentPage, rowsPerPage))
      .then((response) => {
        setActivity(response.data.activity);
        setInfo(response.data.info);
      });
  }, [rowsPerPage, currentPage, type, showExternalOnly]);

  const rowsPerPageChanged = (newValue) => {
    setRowsPerPage(newValue);
  };

  const currentPageChanged = (newValue) => {
    setCurrentPage(newValue);
  };

  const refreshPage = () => {
    setCurrentPage(0);
    fetchActivityData();
    setActivity(emptyArray);
  };

  const toggleExternalOnly = (newValue) => {
    setShowExternalOnly(newValue);
  };

  useEffect(() => {
    fetchActivityData();
  }, [fetchActivityData]);

  return (
    <ActivityTable
      activity={activity}
      info={info}
      rowsPerPage={rowsPerPage}
      rowsPerPageChanged={rowsPerPageChanged}
      currentPage={currentPage}
      currentPageChanged={currentPageChanged}
      refreshPage={refreshPage}
      showExternalOnly={showExternalOnly}
      toggleExternalOnly={toggleExternalOnly}
    />
  );
};

ActivityComponent.propTypes = {
  type: PropTypes.string.isRequired,
};

export default withStyles(styles)(ActivityComponent);
