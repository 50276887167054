import React from 'react';
import {
  withStyles, Paper, Typography, Grid, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cardStyle from './cardStyle';
import GecoRouter from '../../util/GecoRouter';

const ProjectCard = (props) => {
  const {
    classes,
    id,
    time,
    description,
    versionId,
    columnWidths,
  } = props;

  return (
    <Paper className={classes.projectCard}>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="space-around"
      >
        <Grid item xs={columnWidths[0]}>
          <Typography variant="h6" className={classes.diseaseCardTitle}>
            <Link
              to={GecoRouter.docsTab(id, versionId, time)}
              className={classes.noUnderline}
            >
              {id}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={columnWidths[1]}>
          <Typography variant="body1" className={classes.diseaseCardText}>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={columnWidths[2]}>
          <Tooltip title="Versions">
            <Link to={GecoRouter.projectVersions(id)}>
              {time}
            </Link>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
};

ProjectCard.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  columnWidths: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  id: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default withStyles(cardStyle)(ProjectCard);
