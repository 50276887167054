class TabActivityStatus {
  constructor(features) {
    this.features = features;
  }

  static getTopLevelStatus(...rest) {
    return (rest.reduce((acc, cur) => acc + cur) > 0) ? 1 : 0;
  }

  getActivityStatus(key) {
    return this.features.includes(key) ? 1 : 0;
  }
}

export default TabActivityStatus;
