import React, { useEffect, useState } from 'react';
import {
  withStyles, Container, Grid, Typography, Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import styles from '../../style';
import errorHandler from '../../util/requestError';
import ProjectCard from '../card/ProjectCard';
import ApiRouter from '../../util/ApiRouter';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import GecoRouter from '../../util/GecoRouter';
import { emptyArray } from '../../util/util';

const ProjectsList = (props) => {
  const [projectsList, setProjectsList] = useState(emptyArray);
  const [settings, setSettings] = useState(emptyArray);
  const { handleBreadcrumbs } = props;

  useEffect(() => {
    handleBreadcrumbs(GecoBreadcrumbs.projectsList());
    axios
      .get(ApiRouter.projectsList())
      .then((response) => {
        setSettings(response.data.settings);
        setProjectsList(response.data.projects);
      })
      .catch((error) => errorHandler(error));
  }, [handleBreadcrumbs]);

  const columnWidths = [1, 7, 2];

  const { classes } = props;

  return (
    <div className={classes.componentHero}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg">
        <Grid container direction="row" spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.componentTitleDark}>
              Projects
            </Typography>
            <Typography variant="h6" className={classes.componentSubtitleDark}>
              Select a project to dig into our data.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-around"
        >
          <Grid item xs={columnWidths[0]}>
            <Typography variant="subtitle1" className={classes.diseaseAreaLabel}>
              Project id
            </Typography>
          </Grid>
          <Grid item xs={columnWidths[1]}>
            <Typography variant="subtitle1" className={classes.diseaseAreaLabel}>
              Description
            </Typography>
          </Grid>
          <Grid item xs={columnWidths[2]}>
            <Typography variant="subtitle1" className={classes.diseaseAreaLabel}>
              Active version (click to set)
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {projectsList.map((project) => (
            <ProjectCard
              key={project.id}
              id={project.id}
              description={project.description}
              versionId={settings[project.id].project_version_id}
              columnWidths={columnWidths}
              time={settings[project.id].time}
            />
          ))}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '30px' }}>
          <Link href={GecoRouter.gecoApi()}>
            <Typography variant="subtitle1">
              Generable API documentation
            </Typography>
          </Link>
        </Grid>
      </Container>
    </div>
  );
};

ProjectsList.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProjectsList);
