const errorHandler = (error) => {
  if (error.response.status === 401) {
    window.location.reload();
  } else {
    throw new Error(`there was an error: ${error}`);
  }
};

/* eslint-env browser */

export default errorHandler;
