import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, Container, Drawer, Grid, List, ListItem, ListItemText, ListSubheader,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import styles from '../../style';
import GecoRouter from '../../util/GecoRouter';
import CrmAction from '../../util/crmConstants';

const CrmActions = (props) => {
  const { classes, action } = props;
  const [state, setState] = useState(false);

  const getCurrentAction = (selectedAction) => action === selectedAction;

  const toggleDrawer = (open) => {
    setState(open);
  };

  const items = (
    <div>
      <List
        component="nav"
        dense
        subheader={(
          <ListSubheader component="div" id="list">
            Activity
          </ListSubheader>
        )}
      >
        <Link
          to={GecoRouter.crmWebAppActivity()}
          className={classes.noUnderline}
        >
          <ListItem
            button
            selected={getCurrentAction(CrmAction.WEB_ACTIVITY)}
          >
            <ListItemText
              className={classes.drawerNestedItem}
              primary="App activity"
              secondary="Activity in geco web app"
            />
          </ListItem>
        </Link>

        <Link
          to={GecoRouter.crmApiActivity()}
          className={classes.noUnderline}
        >
          <ListItem
            button
            selected={getCurrentAction(CrmAction.API_ACTIVITY)}
          >
            <ListItemText
              className={classes.drawerNestedItem}
              primary="Api activity"
              secondary="Activity in geco API"
            />
          </ListItem>
        </Link>

        <Link
          to={GecoRouter.crmActivityByDays()}
          className={classes.noUnderline}
        >
          <ListItem
            button
            selected={getCurrentAction(CrmAction.ACTIVITY_BY_DAYS)}
          >
            <ListItemText
              className={classes.drawerNestedItem}
              primary="Activity by days"
              secondary="Activity in web app and API by days"
            />
          </ListItem>
        </Link>
      </List>
      <List
        component="nav"
        dense
        subheader={(
          <ListSubheader component="div" id="list">
            Entity management
          </ListSubheader>
        )}
      >
        <Link
          to={GecoRouter.crmCompany()}
          className={classes.noUnderline}
        >
          <ListItem
            button
            selected={getCurrentAction(CrmAction.COMPANY_MANAGEMENT)}
          >
            <ListItemText
              className={classes.drawerNestedItem}
              primary="Company"
              secondary="Company management"
            />
          </ListItem>
        </Link>

        <Link
          to={GecoRouter.crmProject()}
          className={classes.noUnderline}
        >
          <ListItem
            button
            selected={getCurrentAction(CrmAction.PROJECT_MANAGEMENT)}
          >
            <ListItemText
              className={classes.drawerNestedItem}
              primary="Project"
              secondary="Project management"
            />
          </ListItem>
        </Link>

        <Link
          to={GecoRouter.crmUser()}
          className={classes.noUnderline}
        >
          <ListItem
            button
            selected={getCurrentAction(CrmAction.USER_MANAGEMENT)}
          >
            <ListItemText
              className={classes.drawerNestedItem}
              primary="User"
              secondary="User management"
            />
          </ListItem>
        </Link>

        <Link
          to={GecoRouter.crmModelRun()}
          className={classes.noUnderline}
        >
          <ListItem
            button
            selected={getCurrentAction(CrmAction.MODELRUN_MANAGEMENT)}
          >
            <ListItemText
              className={classes.drawerNestedItem}
              primary="Model run"
              secondary="Model run management"
            />
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <Container maxWidth="lg">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={12}>
          <>
            <Button
              color="primary"
              onClick={() => toggleDrawer(true)}
            >
              Show CRM Actions
            </Button>
            <Drawer
              anchor="left"
              open={state}
              onClose={() => toggleDrawer(false)}
            >
              {items}
            </Drawer>
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

CrmActions.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  action: PropTypes.number.isRequired,
};

export default withStyles(styles)(CrmActions);
