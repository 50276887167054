/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const TabPanel = (props) => {
  const {
    children, selectedTab, index, ...rest
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={selectedTab !== index}
      {...rest}
    >
      {children}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedTab: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabPanel;
