// See: https://github.com/generable/geco/blob/develop/docs/internal-documentation.md#graphs

const chartStyle = {
  width: '100%',
};

const basicLayout = {
  margin: { // remove title area
    l: 75,
    r: 50,
    b: 75,
    t: 25,
    pad: 0,
  },
  xaxis: {
    zeroline: false,
  },
  yaxis: {
    zeroline: false,
  },
  autosize: true,
  showlegend: true,
  legend: {
    x: 1,
    y: 1,
    xanchor: 'right',
  },
};

const chartBaseLayout = {
  font: {
    family: 'Open Sans',
    size: 16,
    color: '#000000',
  },
  margin: { // remove title area
    l: 75,
    r: 50,
    b: 75,
    t: 25,
    pad: 0,
  },
  xaxis: {
    linecolor: '#b4b1b7',
    linewidth: 2,
  },
  yaxis: {
    linecolor: '#b4b1b7',
    linewidth: 2,
  },
  autosize: true,
};

function chartLayout(xLabel, yLabel, xgrid = false, ygrid = false) {
  const layout = { ...chartBaseLayout };
  Object.assign(layout, {
    xaxis: {
      title: {
        text: xLabel,
        font: {
          size: 14,
        },
      },
      showgrid: xgrid,
      visible: true,
      color: '#37343b',
      tickmode: 'auto',
      nticks: 4,
      showline: true,
      linecolor: '#b4b1b7',
      linewidth: 2,
      ticks: 'outside',
      ticklen: 8,
      tickcolor: '#ffffff',
    },
    yaxis: {
      title: {
        text: yLabel,
        font: {
          size: 14,
        },
      },
      showgrid: ygrid,
      visible: true,
      color: '#37343b',
      tickmode: 'auto',
      nticks: 3,
      showline: true,
      linecolor: '#b4b1b7',
      linewidth: 2,
      ticks: 'outside',
      ticklen: 8,
      tickcolor: '#ffffff',
      rangemode: 'tozero',
    },
  });
  return layout;
}

const grayColor = (alpha = 0.3) => `rgba(163, 158, 169, ${alpha})`;
const primaryColor = 'rgba(145, 47, 255, 0.8)';

function generateColors(alpha = 0.4) {
  return [
    `rgba(133, 217, 88, ${alpha})`,
    `rgba(51, 197, 255, ${alpha})`,
    `rgba(112, 51, 255, ${alpha})`,
    `rgba(255, 144, 102, ${alpha})`,
    `rgba(255, 204, 64, ${alpha})`,
    `rgba(255, 0, 0, ${alpha})`,

    `rgba(194, 236, 172, ${alpha})`,
    `rgba(153, 226, 255, ${alpha})`,
    `rgba(184, 153, 255, ${alpha})`,
    `rgba(255, 181, 153, ${alpha})`,
    `rgba(255, 228, 153, ${alpha})`,
    `rgba(255, 153, 153, ${alpha})`,

    `rgba(62, 125, 28, ${alpha})`,
    `rgba(0, 109, 153, ${alpha})`,
    `rgba(46, 0, 153, ${alpha})`,
    `rgba(153, 42, 0, ${alpha})`,
    `rgba(153, 112, 0, ${alpha})`,
    `rgba(153, 0, 0, ${alpha})`,
  ];
}

const pointColors = generateColors(1.0);

const chartColors = generateColors(0.8);

const chartColorsEnvelope = generateColors;

export {
  basicLayout,
  chartBaseLayout,
  chartColors,
  chartColorsEnvelope,
  chartLayout,
  chartStyle,
  generateColors,
  grayColor,
  pointColors,
  primaryColor,
};
