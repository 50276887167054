import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Container, Grid, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import GecoRouter from '../../util/GecoRouter';
import styles from '../../style';
import { GecoTabs, GecoTab1 } from '../route/Tab';
import DocsActivityStatus from '../../util/DocsActivityStatus';
import RunsActivityStatus from '../../util/RunsActivityStatus';

const AppHeader = (props) => {
  const {
    classes,
    projectId,
    versionId,
    selectedTab,
    time,
    projects,
  } = props;

  const features = projects[projectId];
  const docsStatus = new DocsActivityStatus(features);
  const runsStatus = new RunsActivityStatus(features);

  return (
    <Container maxWidth="lg">
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h4"
            className={classNames(classes.componentTitle, classes.componentTrialVSpace)}
          >
            {projectId}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classNames(classes.componentLabel, classes.componentTrialLabel)}
          >
            Version:
            &nbsp;
            <Link to={GecoRouter.projectVersions(projectId)}>
              {time}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <GecoTabs
            value={selectedTab}
            aria-label="ant example"
          >
            <GecoTab1
              active={docsStatus.all}
              label="Docs"
              component={Link}
              to={GecoRouter.docsTab(projectId, versionId, time)}
            />
            <GecoTab1
              active={runsStatus.all}
              label="Runs"
              component={Link}
              to={GecoRouter.runsTab(projectId, versionId, time)}
            />
          </GecoTabs>
        </Grid>
      </Grid>
    </Container>
  );
};

AppHeader.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  projectId: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  selectedTab: PropTypes.number.isRequired,
  projects: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(AppHeader);
