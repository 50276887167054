/* eslint-disable react/jsx-props-no-spreading */
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ListItemText from '@material-ui/core/ListItemText';
import PowerSettingNew from '@material-ui/icons/PowerSettingsNew';
import PropTypes from 'prop-types';
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { Breadcrumbs, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link as LinkRouter } from 'react-router-dom';
import styles from '../../style';
import ApiRouter from '../../util/ApiRouter';
import { version } from '../../../package.json';
import GecoRouter from '../../util/GecoRouter';

const handleLogout = () => {
  axios
    .get(ApiRouter.logout())
    .then(() => {
      // eslint-disable-next-line no-undef
      window.location.reload();
    });
};

const GecoLink = (props) => <Link {...props} component={LinkRouter} />;

const GecoAppBar = (props) => {
  const {
    classes, breadCrumbs, isAdmin,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isProjectsList = breadCrumbs.length === 1 && breadCrumbs[0].label === 'Projects List';

  return (
    <AppBar
      position="absolute"
      color="default"
      elevation={0}
      className={classNames(classes.appBar)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classNames(classes.menuButton)}
        >
          <MenuIcon className={isProjectsList ? classes.menuIconDark : ''} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <MenuItem disabled>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText>
              v
              {version}
            </ListItemText>
          </MenuItem>

          <LinkRouter
            to={GecoRouter.userSettings()}
            className={classes.noUnderline}
          >
            <MenuItem>
              <ListItemIcon><SettingsApplicationsIcon /></ListItemIcon>
              <ListItemText>User settings</ListItemText>
            </MenuItem>
          </LinkRouter>

          {isAdmin && (
            <LinkRouter
              to={GecoRouter.crmWebAppActivity()}
              className={classes.noUnderline}
            >
              <MenuItem>
                <ListItemIcon><ShowChartIcon /></ListItemIcon>
                <ListItemText>CRM</ListItemText>
              </MenuItem>
            </LinkRouter>
          )}

          <MenuItem onClick={handleLogout}>
            <ListItemIcon><PowerSettingNew /></ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
        {!isProjectsList && (
          <div>
            <Breadcrumbs aria-label="Breadcrumb">
              {breadCrumbs.map((breadCrumb, index) => {
                const last = index === breadCrumbs.length - 1;
                return (
                  <GecoLink
                    key={breadCrumb.label}
                    to={breadCrumb.link}
                    color="inherit"
                    className={classNames(last ? classes.breadCrumbFocus : '')}
                  >
                    {breadCrumb.label}
                  </GecoLink>
                );
              })}
            </Breadcrumbs>
          </div>
        )}
        <div className={classes.grow} />

        <GecoLink to="/">
          <img
            src={isProjectsList ? '/img/logo_dark.svg' : '/img/logo.svg'}
            className={classes.generableLogo}
            alt="Generable"
          />
        </GecoLink>
      </Toolbar>
    </AppBar>
  );
};

GecoAppBar.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  breadCrumbs: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isAdmin: PropTypes.bool.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(GecoAppBar);
