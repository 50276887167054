/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import GecoAppBar from './GecoAppBar';
import styles from '../../style';
import ProjectsList from './ProjectsList';
// import Decision from './Decision';
import GecoRouter from '../../util/GecoRouter';
// import Prediction from './Prediction';
// import Validation from './Validation';
import ProjectVersions from './ProjectVersions';
import UserSettings from './UserSettings';
import ProjectRoute from '../route/ProjectRoute';
import DocsTab from './DocsTab';
import WebAppActivity from '../crm/WebAppActivity';
import ApiActivity from '../crm/ApiActivity';
import ActivityByDays from '../crm/ActivityByDays';
import RunsTab from './RunsTab';
import CrmCompany from '../crm/CrmCompany';
import CrmProject from '../crm/CrmProject';
import CrmUser from '../crm/CrmUser';
import CrmModelRun from '../crm/CrmModelRun';

const Dashboard = (props) => {
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  const handleBreadcrumbs = useCallback((bc) => {
    setBreadCrumbs(bc);
  }, []);

  const { classes, isAdmin, projects } = props;

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <GecoAppBar breadCrumbs={breadCrumbs} isAdmin={isAdmin} />

        <Route
          exact
          path={GecoRouter.projectsList()}
          render={(componentProps) => (
            <ProjectsList {...componentProps} handleBreadcrumbs={handleBreadcrumbs} />
          )}
        />
        <Route
          exact
          path={GecoRouter.projectVersions()}
          render={(componentProps) => (
            <ProjectVersions {...componentProps} handleBreadcrumbs={handleBreadcrumbs} />
          )}
        />
        <Route
          exact
          path={GecoRouter.userSettings()}
          render={(componentProps) => (
            <UserSettings {...componentProps} handleBreadcrumbs={handleBreadcrumbs} />
          )}
        />
        <Route
          exact
          path={GecoRouter.crmWebAppActivity()}
          render={(componentProps) => (
            <WebAppActivity
              {...componentProps}
              handleBreadcrumbs={handleBreadcrumbs}
              isAdmin={isAdmin}
            />
          )}
        />
        <Route
          exact
          path={GecoRouter.crmApiActivity()}
          render={(componentProps) => (
            <ApiActivity
              {...componentProps}
              handleBreadcrumbs={handleBreadcrumbs}
              isAdmin={isAdmin}
            />
          )}
        />
        <Route
          exact
          path={GecoRouter.crmActivityByDays()}
          render={(componentProps) => (
            <ActivityByDays
              {...componentProps}
              handleBreadcrumbs={handleBreadcrumbs}
              isAdmin={isAdmin}
            />
          )}
        />
        <Route
          exact
          path={GecoRouter.crmCompany()}
          render={(componentProps) => (
            <CrmCompany
              {...componentProps}
              handleBreadcrumbs={handleBreadcrumbs}
              isAdmin={isAdmin}
            />
          )}
        />
        <Route
          exact
          path={GecoRouter.crmProject()}
          render={(componentProps) => (
            <CrmProject
              {...componentProps}
              handleBreadcrumbs={handleBreadcrumbs}
              isAdmin={isAdmin}
            />
          )}
        />
        <Route
          exact
          path={GecoRouter.crmUser()}
          render={(componentProps) => (
            <CrmUser
              {...componentProps}
              handleBreadcrumbs={handleBreadcrumbs}
              isAdmin={isAdmin}
            />
          )}
        />
        <Route
          exact
          path={GecoRouter.crmModelRun()}
          render={(componentProps) => (
            <CrmModelRun
              {...componentProps}
              handleBreadcrumbs={handleBreadcrumbs}
              isAdmin={isAdmin}
            />
          )}
        />

        <ProjectRoute
          exact
          path={GecoRouter.docsTab()}
          handleBreadcrumbs={handleBreadcrumbs}
          component={DocsTab}
          tabLevel1Index={0}
          projects={projects}
        />

        <ProjectRoute
          exact
          path={GecoRouter.runsTab()}
          handleBreadcrumbs={handleBreadcrumbs}
          component={RunsTab}
          tabLevel1Index={1}
          projects={projects}
        />

        {/* <TrialDetailsRoute
          exact
          path={GecoRouter.prediction()}
          handleBreadcrumbs={handleBreadcrumbs}
          handleCohorts={handleCohorts}
          handleValidation={handleValidation}
          handleFeatures={handleFeatures}
          showValidation={showValidation}
          selectedCohortId={selectedCohortId}
          cohorts={cohorts}
          cohortsForTrial={cohortsForTrial}
          component={Prediction}
          features={features}
          tabLevel1Index={1}
        />
        <TrialDetailsRoute
          exact
          path={GecoRouter.decision()}
          handleBreadcrumbs={handleBreadcrumbs}
          handleCohorts={handleCohorts}
          handleValidation={handleValidation}
          handleFeatures={handleFeatures}
          showValidation={showValidation}
          selectedCohortId={selectedCohortId}
          cohorts={cohorts}
          cohortsForTrial={cohortsForTrial}
          component={Decision}
          features={features}
          tabLevel1Index={2}
        />
        <TrialDetailsRoute
          exact
          path={GecoRouter.validation()}
          handleBreadcrumbs={handleBreadcrumbs}
          handleCohorts={handleCohorts}
          handleValidation={handleValidation}
          handleFeatures={handleFeatures}
          showValidation={showValidation}
          selectedCohortId={selectedCohortId}
          cohorts={cohorts}
          cohortsForTrial={cohortsForTrial}
          component={Validation}
          features={features}
          tabLevel1Index={3}
        /> */}
      </div>
    </Router>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  projects: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isAdmin: PropTypes.bool.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(Dashboard);
