import React from 'react';
import Score from './baseScore';

class CategoricalScore extends Score {
  static fillAlpha = 0.3;

  static fillRed = `rgba(255, 0, 0, ${CategoricalScore.fillAlpha})`;

  static fillYellow = `rgba(255, 204, 102, ${CategoricalScore.fillAlpha})`;

  static fillGreen = `rgba(0, 255, 0, ${CategoricalScore.fillAlpha})`;

  renderScoreComponent(ctx, canvasWidth, canvasHeight) {
    const startYOffset = 13;
    const endYOffset = 20;

    switch (this.score.value) {
      case 'bad': {
        ctx.fillStyle = CategoricalScore.fillRed;
        break;
      }

      case 'good': {
        ctx.fillStyle = CategoricalScore.fillYellow;
        break;
      }

      case 'excellent': {
        ctx.fillStyle = CategoricalScore.fillGreen;
        break;
      }

      default: {
        throw new Error(`${this.score.value} is not supported value for CategoricalScore`);
      }
    }

    ctx.fillRect(
      0,
      startYOffset,
      canvasWidth,
      canvasHeight - endYOffset,
    );
  }

  getScoreToolTip() {
    const value = this.score.value[0].toUpperCase() + this.score.value.slice(1);
    return (
      <div>
        {this.description}
        :
        &nbsp;
        {value}
      </div>
    );
  }
}

export default CategoricalScore;
