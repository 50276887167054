const CrmAction = {
  WEB_ACTIVITY: 0,
  API_ACTIVITY: 1,
  ACTIVITY_BY_DAYS: 2,
  COMPANY_MANAGEMENT: 3,
  PROJECT_MANAGEMENT: 4,
  USER_MANAGEMENT: 5,
  MODELRUN_MANAGEMENT: 6,
};

Object.freeze(CrmAction);

export default CrmAction;
