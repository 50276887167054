class ApiRouter {
  static prefix = '/api';

  static login() {
    return `${this.prefix}/login`;
  }

  static logout() {
    return `${this.prefix}/logout`;
  }

  static loggedin() {
    return `${this.prefix}/loggedin`;
  }

  static getUserInfo() {
    return `${this.prefix}/user/info`;
  }

  static setPassword() {
    return `${this.prefix}/user/set_password`;
  }

  static getRunsList(projectId, projectVersionId) {
    return `${this.prefix}/runs/list/${projectId}/${projectVersionId}`;
  }

  static getWebActivity(type, showExternalOnly, page, rowsPerPage) {
    return `${this.prefix}/crm/activity/${type}/${showExternalOnly}/${page}/${rowsPerPage}`;
  }

  static getActivityByDays(days) {
    return `${this.prefix}/crm/activity/days/${days}`;
  }

  static crmGetCompanies() {
    return `${this.prefix}/crm/companies`;
  }

  static crmAddCompany() {
    return `${this.prefix}/crm/company/add`;
  }

  static crmGetProjects() {
    return `${this.prefix}/crm/projects`;
  }

  static crmGetUsers() {
    return `${this.prefix}/crm/users`;
  }

  static crmAddUser() {
    return `${this.prefix}/crm/user/add`;
  }

  static crmDeactivateUser() {
    return `${this.prefix}/crm/user/deactivate`;
  }

  static crmSaveProjectConfigurations() {
    return `${this.prefix}/crm/projects/configuration/save`;
  }

  static crmAssignTag() {
    return `${this.prefix}/crm/tags/assign`;
  }

  static crmRemoveTag() {
    return `${this.prefix}/crm/tags/remove`;
  }

  static projectsList() {
    return `${this.prefix}/projects_list`;
  }

  static docsProjectVersion(projectId, projectVersionId) {
    return `${this.prefix}/docs/project_versions/${projectId}/${projectVersionId}`;
  }

  static docProjectVersion(projectId, projectVersionId, fileName) {
    return `${this.prefix}/docs/project_version/${projectId}/${projectVersionId}/${fileName}`;
  }

  static runDetails(projectId, projectVersionId, runId, trialName) {
    return `${this.prefix}/runs/details/${projectId}/${projectVersionId}/${runId}/${trialName}`;
  }

  static runDetailsInfo(projectId, projectVersionId, runId) {
    return `${this.prefix}/runs/details/info/${projectId}/${projectVersionId}/${runId}`;
  }

  static projectVersions(projectId) {
    return `${this.prefix}/project/versions/${projectId}/`;
  }

  static configuration(shortName, trialName) {
    return `${this.prefix}/configuration/${shortName}/${trialName}/`;
  }

  static setUserVersion() {
    return `${this.prefix}/user/version/`;
  }
}

export default ApiRouter;
