import React, {
  useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  Table, TableBody, TableCell, TableHead, TableRow, withStyles, TableSortLabel,
} from '@material-ui/core';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import GetAppIcon from '@material-ui/icons/GetApp';
import styles from '../../style';
import ApiRouter from '../../util/ApiRouter';
import { getDateFromTimestamp } from '../../util/util';

const DocsTable = (props) => {
  const { docs, projectId, versionId } = props;
  const asc = 'asc';
  const desc = 'desc';
  const [sortKey, setSortKey] = useState('');
  const [sortOrders, setSortOders] = useState({
    Created: asc,
    Author: asc,
    Name: asc,
  });
  const [setSortedList] = useState([]);

  const sortKeys = {
    Created: 'created_at',
    Author: 'author',
    Name: 'file_name',
  };

  const sortOrderHandler = useCallback((header) => {
    setSortKey(header);
    const newSortOrders = { ...sortOrders };
    newSortOrders[header] = (newSortOrders[header] === asc) ? desc : asc;
    setSortOders(newSortOrders);

    const sortCriteria = sortKeys[header];
    const sortedDocsList = docs.sort((a, b) => {
      if (a[sortCriteria].toLowerCase() < b[sortCriteria].toLowerCase()) {
        return (newSortOrders[header] === asc) ? -1 : 1;
      }
      return (newSortOrders[header] === asc) ? 1 : -1;
    });
    setSortedList(sortedDocsList);
  }, [sortOrders, docs, sortKey]);

  const linkToFile = (fileName) => {
    const openExtensions = ['.html', '.pdf', '.txt'];
    const ext = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
    if (openExtensions.includes(ext)) {
      return (
        <Link
          href={ApiRouter.docProjectVersion(projectId, versionId, fileName)}
          target="_blank"
        >
          {fileName}
        </Link>
      );
    }
    return (
      <Link
        href={ApiRouter.docProjectVersion(projectId, versionId, fileName)}
        download
      >
        {fileName}
      </Link>
    );
  };

  const getIconFetch = (fileName) => {
    const openExtensions = ['.html', '.pdf', '.txt'];
    const ext = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
    if (openExtensions.includes(ext)) {
      return <OpenInBrowserIcon />;
    }
    return <GetAppIcon />;
  };

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortKey === 'Name'}
                direction={sortOrders.Name}
                onClick={() => sortOrderHandler('Name')}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortKey === 'Author'}
                direction={sortOrders.Author}
                onClick={() => sortOrderHandler('Author')}
              >
                Author
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">
              <TableSortLabel
                active={sortKey === 'Created'}
                direction={sortOrders.Created}
                onClick={() => sortOrderHandler('Created')}
              >
                Created at
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">Open/Fetch</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {docs.map((doc) => (
            <TableRow key={doc.file_name}>
              <TableCell>
                {linkToFile(doc.file_name)}
              </TableCell>
              <TableCell>{doc.author}</TableCell>
              <TableCell align="right">{getDateFromTimestamp(doc.created_at, false)}</TableCell>
              <TableCell align="center">
                {getIconFetch(doc.file_name)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

DocsTable.propTypes = {
  docs: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  projectId: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
};

export default withStyles(styles)(DocsTable);
