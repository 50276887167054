import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Container, Grid,
} from '@material-ui/core';
import styles from '../../style';
import GecoBreadcrumbs from '../../util/GecoBreadcrumbs';
import { GecoTabs, GecoTab2 } from '../route/Tab';
import TabPanel from '../route/TabPanel';
import RunList from '../tool/RunList';
import RunsActivityStatus from '../../util/RunsActivityStatus';
import RunDetails from '../tool/RunDetails';

const RunsTab = (props) => {
  const {
    classes, handleBreadcrumbs, match, projects,
  } = props;
  const { params } = match;
  const { projectId, versionId } = params;

  const [tabSelevel2, setTabLevel2] = useState(0);
  // const [tabSelevel2, setTabLevel2] = useState(1);

  const features = projects[params.projectId];
  const tabStatus = new RunsActivityStatus(features);

  useEffect(() => {
    handleBreadcrumbs(GecoBreadcrumbs.docsTab(projectId, versionId));
  }, [handleBreadcrumbs, projectId, versionId]);

  return (
    <div>
      <Container>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={10}>
            <div>
              <GecoTabs
                className={classes.tabLevel2}
                value={tabSelevel2}
                onChange={(_, newValue) => {
                  setTabLevel2(newValue);
                }}
                aria-label="second level"
              >
                <GecoTab2 active={tabStatus.list} label="List" />
                <GecoTab2 active={tabStatus.runDetails} label="Run Details" />
              </GecoTabs>

              <TabPanel index={0} selectedTab={tabSelevel2}>
                <RunList
                  projectId={projectId}
                  versionId={versionId}
                />
              </TabPanel>
              <TabPanel index={1} selectedTab={tabSelevel2}>
                <RunDetails
                  projectId={projectId}
                  versionId={versionId}
                />
              </TabPanel>
            </div>

          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

RunsTab.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  projects: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleBreadcrumbs: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(RunsTab);
