import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../style';
import { chartColors, chartStyle } from './charts';

const ActivityChart = (props) => {
  const { activity, title } = props;

  const layout = {
    title: {
      text: title,
    },
    xaxis: {
      title: 'UTC Date',
      font: {
        size: 14,
      },
    },
    yaxis: {
      title: 'Number of events',
      font: {
        size: 14,
      },
    },
    barmode: 'relative',
  };

  const data = activity.map((el, index) => ({
    name: el.name,
    type: 'bar',
    x: el.x,
    y: el.y,
    marker: {
      color: chartColors[index],
    },
  }));

  return (
    <div>
      <Plot
        useResizeHandler
        style={chartStyle}
        data={data}
        layout={layout}
      />
    </div>
  );
};

ActivityChart.propTypes = {
  activity: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(ActivityChart);
