const cardStyle = {
  table: {
    margin: 'auto',
  },
  cardTitle: {
    float: 'left',
  },
  diseaseCardTitle: {
    float: 'left',
    fontSize: '1.25em',
    color: '#050540',
    '&:hover': {
      color: '#7033ff',
      textDecoration: 'underline',
    },
  },
  diseaseDemoCardTitle: {
    float: 'left',
    fontSize: '1.25em',
    color: '#050540',
  },
  diseaseCardText: {
    float: 'left',
    fontSize: '1.25em',
    color: '#050540',
    textAlign: 'left',
  },
  projectCard: {
    marginLeft: '-35px',
    marginRight: '-35px',
    paddingLeft: '35px',
    paddingRight: '35px',
    paddingTop: '41px',
    paddingBottom: '38px',
    marginBottom: '7px',
  },
  noUnderline: {
    textDecoration: 'none',
  },
};

export default cardStyle;
