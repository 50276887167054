/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core';

const getBackgroundColor = (active) => ((!active) ? '#d7dae0' : '#eff3f9');

const GecoTabs = withStyles({
  root: {
    borderBottom: '0px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#912FFF',
  },
})(Tabs);

const GecoTab1 = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 140,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1.375rem',
    paddingBottom: '0.7em',
    marginRight: theme.spacing(4),
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Open Sans'].join(','),
    '&:hover': {
      color: '#912FFF',
      opacity: 1,
    },
    '&$selected': {
      color: '#050540',
    },
    '&:focus': {
      color: '#912FFF',
    },
  },
  selected: {},
}))((props) => {
  const { active } = props;

  return (
    <Tab
      disableRipple
      disabled={!active}
      style={{ backgroundColor: getBackgroundColor(active) }}
      {...props}
    />
  );
});

const GecoTab2 = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 140,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 16,
    paddingBottom: '0.7em',
    backgroundColor: '#f4f7f8',
    marginRight: theme.spacing(4),
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Open Sans'].join(','),
    '&:hover': {
      color: '#912FFF',
      opacity: 1,
    },
    '&$selected': {
      color: '#050540',
    },
    '&:focus': {
      color: '#912FFF',
    },
  },
  selected: {},
}))((props) => {
  const { active } = props;

  return (
    <Tab
      disableRipple
      // disabled={active === 'inactive'}
      disabled={!active}
      style={{ backgroundColor: getBackgroundColor(active) }}
      {...props}
    />
  );
});

export {
  GecoTabs, GecoTab1, GecoTab2,
};
