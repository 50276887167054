import React, { Component } from 'react';
import axios from 'axios';
import './App.css';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Dashboard from './component/common/Dashboard';
import Login from './component/common/Login';
import Welcome from './component/common/Welcome';
import errorHandler from './util/requestError';
import ApiRouter from './util/ApiRouter';
import { getRedirectLink } from './util/util';

const theme = createTheme({
  palette: {
    primary: {
      main: '#912FFF',
    },
    text: {
      secondary: '#B5B5B5',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 16,
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.375rem',
      letterSpacing: '-0.01em',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.33rem',
      letterSpacing: '0.01em',
    },
  },
  overrides: {
    MuiBreadcrumbs: {
      li: {
        fontSize: '1rem',
        letterSpacing: '-0.01em',
        color: 'textSecondary',
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '1em',
        fontWeight: 600,
        color: '#050540',
        paddingLeft: '34px',
      },
      body: {
        fontSize: '1em',
        color: '#050540',
        paddingLeft: '34px',
      },
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          backgroundColor: '#f0eff0',
        },
      },
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: <Welcome />,
    };
  }

  componentDidMount() {
    axios
      .get(ApiRouter.loggedin())
      .then((response) => {
        if (response.data.loggedin === null) {
          this.setState({ page: <Login /> });
        } else {
          const fullUrl = getRedirectLink(window.location.href);
          if (fullUrl !== null) {
            window.location.href = fullUrl;
            return;
          }
          this.setState({
            page: <Dashboard
              isAdmin={response.data.loggedin.is_admin}
              projects={response.data.projects}
            />,
          });
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  render() {
    const { page } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          {page}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
