import React from 'react';
import { Tooltip } from '@material-ui/core';
import StarRateIcon from '@material-ui/icons/StarRate';
import ArchiveIcon from '@material-ui/icons/Archive';
import { chartColors } from '../component/chart/charts';

const colors = chartColors;

const renderTag = (tag, index) => {
  switch (tag) {
    case 'starred':
      return (
        <div
          key={tag}
          style={{
            width: '25px',
            height: '25px',
          }}
        >
          <Tooltip title={tag}>
            <StarRateIcon style={{ color: colors[index] }} />
          </Tooltip>
        </div>
      );
    case 'archived':
      return (
        <div
          key={tag}
          style={{
            width: '25px',
            height: '25px',
          }}
        >
          <Tooltip title={tag}>
            <ArchiveIcon style={{ color: colors[index] }} />
          </Tooltip>
        </div>
      );
    default:
      return (
        <Tooltip title={tag}>
          <div
            key={tag}
            style={{
              width: '15px',
              height: '15px',
              background: colors[index],
              borderRadius: '5px',
            }}
          />
        </Tooltip>
      );
  }
};

export default renderTag;
