import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, FormControl, TextField, Button, Grid,
} from '@material-ui/core';
import styles from '../../style';
import TrialSelector from './TrialSelector';

const RunSelector = (props) => {
  const {
    classes, onModelRunIdChange, onClearRunId, runId, trials, selectedTrial, onSelectedTrialChange,
  } = props;

  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        justifyContent="flex-start"
      >
        <Grid item>
          <FormControl>
            <TextField
              className={classes.formElementWide}
              label="Run id"
              name="runId"
              onChange={onModelRunIdChange}
              value={runId}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <TrialSelector
            trials={trials}
            selectedTrial={selectedTrial}
            onSelectedTrialChange={onSelectedTrialChange}
          />
        </Grid>

        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            onClick={onClearRunId}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

RunSelector.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onModelRunIdChange: PropTypes.func.isRequired,
  onClearRunId: PropTypes.func.isRequired,
  runId: PropTypes.string.isRequired,
  selectedTrial: PropTypes.string.isRequired,
  trials: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onSelectedTrialChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(RunSelector);
