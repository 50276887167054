import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, withStyles, Snackbar,
  Grid,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Alert from '@material-ui/lab/Alert';
import styles from '../../style';
import { emptyArray, getDateFromTimestamp } from '../../util/util';
import { createScore, getScore } from '../../util/modelRunScores';
import renderTag from '../../util/modelRunTags';

const ModelRunsTable = (props) => {
  const {
    classes,
    description,
    modelRuns,
    modelType,
    projectId,
    runId,
    seed,
    version,
  } = props;

  const asc = 'asc';
  const desc = 'desc';
  // const fixedTableHeader = ['Description', 'Seed', 'Type', 'Version', 'Started'];
  // const fixedTableHeader = ['Description', 'Seed', 'Type', 'Started'];
  const fixedTableHeader = ['Tags', 'Seed', 'Type', 'Started'];
  const fixedHeaderDescriptions = {
    // Description: 'Dataset description',
    Tags: 'Model run tags',
    Seed: 'The seed identifies a unique set of subjects',
    Type: 'Model type: biomarker, hazard, joint, pk, pkpd',
    Version: 'Model version',
    Started: 'Run start time',
  };
  const sortKeys = {
    // Description: 'description',
    Seed: 'seed_subjects',
    Type: 'type',
    Version: 'version',
    Started: 'run_started_on',
  };
  const canvasWidtht = 150;
  const canvasHeightt = 30;
  const canvasParams = [];

  const [sortKey, setSortKey] = useState('');
  const [sortOrders, setSortOders] = useState({
    Description: asc,
    Seed: asc,
    Type: asc,
    Version: asc,
    Started: asc,
  });
  const [sortedList, setSortedList] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [clipboardId, setClipboardId] = useState(null);
  const [tableHeader, setTableHeader] = useState(emptyArray);
  const [headerDescriptions, setHeaderDescriptions] = useState(emptyArray);

  const headerReady = (tableHeader.length !== 0);

  const allTags = [
    ...new Set(sortedList.flatMap((run) => run.tags).filter((tag) => tag !== null))].sort();

  const sortOrderHandler = useCallback((header) => {
    setSortKey(header);
    const newSortOrders = { ...sortOrders };
    newSortOrders[header] = (newSortOrders[header] === asc) ? desc : asc;
    setSortOders(newSortOrders);

    const sortCriteria = sortKeys[header];
    const sortedModelRunList = [...modelRuns.run_list].sort((a, b) => {
      if (a[sortCriteria] < b[sortCriteria]) {
        return (newSortOrders[header] === asc) ? -1 : 1;
      }
      return (newSortOrders[header] === asc) ? 1 : -1;
    });
    setSortedList(sortedModelRunList);
  }, [sortOrders, modelRuns, sortKey]);

  const applyFilters = useCallback(() => {
    const runs = (modelRuns.run_list !== undefined) ? modelRuns.run_list : [];

    let newModelRunList = [...runs];
    if (runId) newModelRunList = newModelRunList.filter((v) => v.run_id === runId);
    if (description) newModelRunList = newModelRunList.filter((v) => v.description === description);
    if (seed) newModelRunList = newModelRunList.filter((v) => v.seed_subjects === seed);
    if (modelType) newModelRunList = newModelRunList.filter((v) => v.type === modelType);
    if (version) newModelRunList = newModelRunList.filter((v) => v.version === version);
    setSortedList(newModelRunList);
  }, [
    description,
    modelRuns,
    modelType,
    runId,
    seed,
    version,
  ]);

  const copyToClipboard = useCallback((value) => {
    navigator.clipboard.writeText(value); // eslint-disable-line no-undef
    setSnackOpen(true);
    setClipboardId(value);
  }, []);

  const onSnackClose = useCallback(() => {
    setSnackOpen(false);
    setClipboardId(null);
  }, []);

  useEffect(() => {
    applyFilters();
    if (Object.keys(modelRuns).length !== 0) {
      canvasParams.forEach((canvasParam) => {
        const s = createScore(canvasParam.score);
        // eslint-disable-next-line
        const c = document.getElementById(canvasParam.runId);
        const ctx = c.getContext('2d');
        s.renderScoreComponent(ctx, canvasWidtht, canvasHeightt);
      });
      const defaultScore = modelRuns.score_details.default_scores[projectId];
      const th = fixedTableHeader.concat([defaultScore]);
      setTableHeader(th);
      const defaultDescription = modelRuns.score_details.score_descriptions[
        projectId][defaultScore];
      const hd = { ...fixedHeaderDescriptions };
      hd[defaultScore] = defaultDescription;
      setHeaderDescriptions(hd);
    }
  }, [
    headerReady,
    sortedList.length,
    applyFilters,
    modelRuns,
    modelType,
    runId,
    seed,
    version,
  ]);

  return (
    <div>
      {(headerReady) && (
        <>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Tooltip title="Run id">
                  <TableCell
                    align="left"
                    sortDirection="asc"
                  >
                    Id
                  </TableCell>
                </Tooltip>
                {tableHeader.map((header) => (
                  <Tooltip key={header} title={headerDescriptions[header]}>
                    <TableCell
                      align="left"
                      sortDirection="asc"
                      key={header}
                    >
                      <TableSortLabel
                        active={header === sortKey}
                        direction={sortOrders[header]}
                        onClick={() => sortOrderHandler(header)}
                      >
                        {header}
                      </TableSortLabel>
                    </TableCell>
                  </Tooltip>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedList.map((run) => {
                const defaultScoreName = tableHeader[tableHeader.length - 1];
                const score = getScore(run.scores, defaultScoreName);
                const scoreDescription = (score && score.name) ? modelRuns
                  .score_details
                  .score_descriptions[projectId][score.name] : null;

                const s = createScore(score, scoreDescription);
                const scorePresent = (s !== null);
                if (scorePresent) {
                  canvasParams.push({
                    runId: run.run_id,
                    score,
                  });
                }

                return (
                  <TableRow
                    hover
                    key={run.run_id}
                  >
                    <Tooltip title={run.run_id}>
                      <TableCell
                        className={classes.lowPaddingRowColumn}
                        align="right"
                      >
                        {run.run_id.slice(0, 4)}
                        <Tooltip title="Click to copy to clipboard">
                          <FileCopyIcon color="primary" onClick={() => copyToClipboard(run.run_id)} />
                        </Tooltip>
                      </TableCell>
                    </Tooltip>

                    <TableCell>
                      {run.tags !== null && (
                      <Grid container direction="row" alignItems="center">
                        {run.tags.map((tag) => (
                          <Grid item xs={4} key={tag}>
                            {renderTag(tag, allTags.indexOf(tag))}
                          </Grid>
                        ))}
                      </Grid>
                      )}
                    </TableCell>

                    <TableCell align="center">{run.seed_subjects}</TableCell>

                    <TableCell>{run.type}</TableCell>

                    {/* <TableCell>{run.version}</TableCell> */}

                    <Tooltip title={run.run_started_on}>
                      <TableCell>{getDateFromTimestamp(run.run_started_on, false)}</TableCell>
                    </Tooltip>

                    {scorePresent && (
                      <Tooltip title={s.getScoreToolTip()}>
                        <TableCell>
                          <canvas id={run.run_id} width={canvasWidtht} height={canvasHeightt} />
                        </TableCell>
                      </Tooltip>
                    )}
                    {!scorePresent && (
                      <TableCell>
                        <canvas id={run.run_id} width={canvasWidtht} height={canvasHeightt} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Snackbar open={snackOpen} autoHideDuration={5000} onClose={onSnackClose}>
            <Alert severity="info" onClose={onSnackClose}>
              Run id
              {' "'}
              {clipboardId}
              {'" '}
              copied to clipboard.
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
};

ModelRunsTable.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  modelRuns: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  runId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  seed: PropTypes.number.isRequired,
  modelType: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default withStyles(styles)(ModelRunsTable);
