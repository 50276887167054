const emptyArray = [];
const emptyObject = {};

const histologyMapping = {
  adenocarcinoma: 'Adenocarcinoma',
  squamous_cell_carcinoma: 'Squamous Cell Carcinoma',
  large_cell_carcinoma: 'Large Cell Carcinoma',
  other: 'Other',
};

const getEnumMapping = (value) => {
  if (value in histologyMapping) return histologyMapping[value];
  if (value === null) return 'unknown';
  return value;
};

const getDateFromTimestamp = (timeStamp, weekday = true) => {
  const options = (weekday) ? {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } : {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false,
  };
  const df = Intl.DateTimeFormat('en-US', options);
  let ts = timeStamp.replace('GMT', '');
  if (ts.includes('+00:00')) ts = ts.replace(' ', 'T').replace('+00:00', '.000Z');
  return df.format(new Date(ts));
};

const getRedirectLink = (fullPath) => {
  const start = fullPath.indexOf('?url=') + 5;
  if (start === 4) return null;
  const end = fullPath.indexOf('&');
  if (end === -1) return fullPath.slice(start);
  return fullPath.slice(start, end);
};

export {
  emptyArray,
  emptyObject,
  getDateFromTimestamp,
  getEnumMapping,
  getRedirectLink,
};
